<template>
    <div class="modal-dialog modal-xxl p-9">
        <div class="modal-content modal-rounded">
            <div class="modal-header bg-primary py-7 d-flex justify-content-between">
                <h2 class="text-white">{{$t('Upload Image')}} </h2>
                <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal" aria-label="Close" ref="closeStockAccountUploadImageModal" @click="onCloseModal()">
                    <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                        </svg>
                    </span>
                </div>
            </div>
            <div class="modal-body scroll-y m-3" >
                <div class="stepper stepper-links d-flex flex-column" data-kt-stepper="true">
                    <form id="poliformAssetModal" @submit.prevent="" class="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                        <div class="current">
                            <div class="w-100">
                                 <div class="fv-row row">
                                    <div class="col-lg-12 mt-0"> 
                                        <div class="d-flex flex-row row">
                                            <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                                <h2 class="text-black mb-3 text-center justify-content-center">{{this.account.name}} Image Upload</h2>
                                                <div class="file-uploader-block">
                                                    <DxFileUploader
                                                        :accept="'image/*'"
                                                        :multiple="true"
                                                        :allowed-file-extensions="['.jpeg', '.gif', '.png', '.webp', '.svg', '.jpg']"
                                                        upload-url="/api/MediaLibrary/StockUpload"
                                                        upload-mode="useButtons"
                                                        selectButtonText="Select Image"
                                                        :upload-headers="getUploadHeaders()"
                                                        :on-uploaded="getAccountImageData"
                                                        :ref="'fileUploader'"
                                                        :activeStateEnabled="false"
                                                        :allowCanceling="false"
                                                        @valueChanged="e => files = e.value"
                                                    />
                                                </div> 
                                                <small class="note mb-5">
                                                    {{$t('Allowed file extensions:')}}
                                                    <span>{{$t('.jpg, .jpeg, .gif, .png, .webp, .svg')}}</span>.
                                                </small>
                                                <div class="card table p-3"  v-if="imagePreviewList.length > 0" >
                                                    <table class="table">
                                                        <thead class="thead-dark">
                                                            <tr>
                                                            <th scope="col">{{$t('Main')}}</th>
                                                            <th scope="col">{{$t('Image')}}</th>
                                                            <th scope="col">{{$t('Title')}}</th>
                                                            <th scope="col">{{$t('Size')}}</th>
                                                            <th scope="col">{{$t('Extension')}}</th>
                                                             <th scope="col">{{$t('Delete')}}</th> 
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="image in imagePreviewList" :key="image">
                                                                <td> 
                                                                    <input type="checkbox"  class="form-check-input"  v-model="image.is_default" @change="onChangeDefaultImage(image)" >
                                                                </td>
                                                                <td>
                                                                    <img :src="image.url + '/' + image.company_folder + '/' + image.name" class="rounded shadow" width="100" height="100" alt="">
                                                                </td>
                                                                <td>
                                                                    <input type="text" placeholder="Title" class="form-control" v-model="image.title">
                                                                </td>
                                                                <td class="text-muted">{{ image.size }}/kb</td>
                                                                <td class="text-muted">.{{ image.extension }}</td>
                                                                 <th ><i class="bi bi-trash fs-2 cursor-pointer" @click="removeImage(image)"></i></th>
                                                            </tr>    
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div class="col-lg-12 mt-5" v-if="imagePreviewList.length > 0">
                            <div class="d-grid gap-2"> 
                                <button type="submit" class="btn btn-lg btn-block btn-success" @click="uploadImageAccount()" >
                                    <span class="svg-icon svg-icon-2">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                        </svg>
                                    </span>
                                    Save
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isUploadSaving == true">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
 import axios from "axios";
 import { DxFileUploader} from 'devextreme-vue/file-uploader';

export default {
    name: "AccountImageUpdate",
    props:{
        account : Object
    },
    data() {
        return {
            isUploadSaving:false,
            mainImageSelected:null,
            imagePreviewList: [], 
            imageTitle:[],
            files:[],
            folderId: null
        };
    },
    components:{
        DxFileUploader,
    },
    computed: {
	},
    methods: {
        getFolderId(){
            if(this.$refs.fileUploader) this.$refs.fileUploader.instance.reset();
            this.imagePreviewList = [];
            const parameters = { 
                session_id: this.$getUser().session_id,
                folder_name: 'account_images'
            }; 
            axios.post('/api/MediaLibrary/GetLogoFolder', parameters, {'Content-Type': 'application/json'})
            .then((response) => {
                this.folderId = response.data;
                this.getAccountImageData();
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });   
        },
        getAccountImageData(){
            const params = {
                session_id: this.$getUser().session_id,
                account_id : this.account.id
            }
            axios.post('/api/MediaLibrary/GetAccountImages',params,{'Content-Type' : 'application/json'})
            .then((response) => {
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                    this.imagePreviewList = response.data;
                    if (this.imagePreviewList.length > 0) { this.imagePreviewList[0].is_default = true; }
                    if(this.$refs.fileUploader) this.$refs.fileUploader.instance.reset();
                }
            }).catch((error)=>{
                console.log(error)
            });
        }, 
        uploadImageAccount(){
            const titles = this.imagePreviewList.map(image => ({
                id: image.id,
                title: image.title,
                is_default: image.is_default ? 1 : 0
            }));
            const params = {
                session_id : this.$getUser().session_id,
                titles: titles,
            }
            axios.post('/api/MediaLibrary/UpdateStockAccountImageTitle',params,{'Conent-Type':'application/json'})
            .then((response)=>{
                if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                    if(this.$refs.fileUploader) this.$refs.fileUploader.instance.reset();
                    this.$refs.closeStockAccountUploadImageModal.click();
                    this.$emit('refreshImageData'); 
                }
                
            }).catch((error)=>{
                console.log(error);
            })
        },
        getUploadHeaders(){     
            return { 
                sid: this.$getUser().session_id,
                cid: this.$getUser().company_account_id,
                ctd: this.$getUser().controller_account_id,
                stkid: this.account.id,
                fid: this.folderId,
                url: window.location.origin + '/assets/uploads/account_images' 
            };
        },
        onChangeDefaultImage(item){
            this.imagePreviewList.forEach((value) => {  
                if (item.id !== value.id) { 
                    value.is_default = false;
                }
            });
        },
        removeImage(item){
            this.$swal.fire({
                title: "Are you sure you want to delete this image?",
                confirmButtonColor: "#E53935",
                showDenyButton: false,
                showCancelButton: true,
                confirmButtonText: "Yes",
                icon: 'warning'
            })
            .then((result) => {
                if (result.isConfirmed) {
                    const params = {
                        session_id: this.$getUser().session_id,
                        id: item.id,
                        name: item.name,
                        account_id:this.account.id
                    };
                    axios.post("/api/MediaLibrary/DeleteAccountImage",params,{'Content-Type':'application/json'})
                    .then((response)=> {                        
                        if(response.data != null && response != null && response.data != undefined ) {
                            this.getAccountImageData();
                        }
                    }).catch((error)=>{
                        console.log(error);
                    })
                }
            });
        },
        onCloseModal() {
            this.$emit('refreshImageData');
        }
    },
    created() {
        this.getFolderId();
    }
};
</script>
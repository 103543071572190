<template>
    <div>
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3">
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Create New Policy') }}
                </h1>
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1">
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li>
                    <li class="breadcrumb-item text-gray-600">{{ $t('Create New Policy') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li>
                </ul>
            </div>
            <div class="d-flex align-items-center py-1">
                <button v-if="form.activeView > 1" type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3" @click="form.activeView = 1">
                    <span class="ms-2 fs-7"> {{ $t('Return back to criterias') }}</span>
                </button>
                <button class="btn btn-light-danger border border-danger justify-content-end me-3" @click="onClickCancel()" v-if="form.activeView > 1">
                    {{ $t('Cancel') }}
                </button>
                <router-link to="/PolicyList" v-if="this.$isCredentialActive(48,'R')">
                    <button type="button" class="btn btn-light border border-gray-400">
                    <span class="ms-2 fs-7"> {{$t('Transaction List')}}</span>
                    </button>
                </router-link> 
            </div>
        </div>
        <div class="content flex-row-fluid mt-2">
            <RequiredField></RequiredField>
            <div class="card card-xxl-stretch">
                <div class="card-body p-5" v-if="form.activeView > 1">
                    <ul class="nav nav-tabs flex-nowrap w-100 text-nowrap float-end justify-content-end" role="tablist">
                        <li class="nav-item" role="presentation">
                            <a class="nav-link btn btn-active-light btn-color-gray-600 btn-active-color-primary rounded-bottom-0" 
                                :class="{'active border border-bottom-0 border-gray-300': this.activeTab == 'General'}" @click="this.activeTab = 'General'"
                                data-bs-toggle="tab" href="#kt_policy_tab_1" aria-selected="true" role="tab">{{ $t('Policy Card') }}</a>
                        </li>
                    </ul>
                </div>

                <div id="kt_policy_tab_1" class="tab-pane fade show active" v-if="this.activeTab == 'General'" role="tabpanel">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="row p-4" :class="{'pt-5': form.activeView == 2}">
                            <div class="row" v-if="form.activeView == 1">
                                <div class="col-lg-9">
                                    <div class="row g-xl-9">
                                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h3 class="me-3">{{ $t('Select Regulation Type') }}</h3>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="fv-row row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'insurance_type'">
                                                            {{ $t('Insurance Type') }}
                                                            </label>
                                                            <Select2 v-model="form.insurance_type" :options="insuranceTypeList" name="insurance_type" v-custom :settings="{ width: '100%' }"/>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_branch_id'">
                                                            {{ $t('Policy Main Branch') }}
                                                            </label>
                                                            <Select2 v-model="form.main_branch_id" :options="mainBranchList" @select="getPolicyParentList(true)" name="main_branch_id" v-custom :settings="{ width: '100%' }"/>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_branch_id'">
                                                            {{ $t('Policy Sub Branch') }}
                                                            </label>
                                                            <Select2 v-model="form.policy_branch_id" :options="policyBranchList" name="policy_branch_id" v-custom :settings="{ width: '100%' }"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                                <div class="card-header">
                                                    <div class="card-title">
                                                        <h3 class="text-gray-800 me-3" v-tag="'contact_name'"> {{ $t('Select Insurer Account') }} </h3>
                                                    </div>
                                                    <div class="card-toolbar">
                                                        <button ref="AddNewContactButton" type="button" class="d-none btn btn-light-primary border border-primary btn-sm justify-content-end"
                                                            @click="openCreateContactModal()" data-bs-toggle="modal" data-bs-target="#kt_modal_add_contact">
                                                            <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                    viewBox="0 0 24 24" fill="none">
                                                                    <path
                                                                        d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                                                                        fill="currentColor"></path>
                                                                    <path opacity="0.3"
                                                                        d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                                                                        fill="currentColor"></path>
                                                                </svg>
                                                            </span>
                                                            {{ $t('Create New Account') }}
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <div class="fv-row row">
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3">
                                                                {{ $t('Insurer Account') }}
                                                            </label>
                                                            <div class="input-group">
                                                                <button type="button" class="btn rounded-start-0 btn-light btn-icon text-hover-primary border border-1 border-gray-300 z-index-0"
                                                                    data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()"
                                                                    v-if="form.account_id !== null && form.account_id !== '' && form.account_id !== undefined ">
                                                                    <i class="fa fa-eye text-gray-600 fs-3"></i>
                                                                </button>
                                                                <input type="search" class="form-control form-control-lg" name="contact_name" v-model="form.account_name" @input="form.account_id = null" v-custom :placeholder="$t('Please type an account name and click on search button or press enter')" @keyup.enter="onClickSearchButton()">
                                                                <button ref="searchAccountButton" type="button" class="btn btn-sm btn-light-primary border border-gray-300 z-index-0" data-bs-toggle="modal" data-bs-target="#kt_search_account_modal" 
                                                                    data-kt-indicator="on" @click="openSearchModal(form.account_name)">
                                                                    <i class="fa fa-search"></i>
                                                                    {{ $t('Search') }}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-lg-12">
                                                            <label class="fs-6 fw-bold form-label mt-3" v-tag="'main_producer_id'">
                                                            {{ $t('Producer Company') }}
                                                            </label>
                                                            <Select2 v-model="form.main_producer_id" :options="producerList" name="main_producer_id" v-custom :settings="{ width: '100%' }"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div class="col-lg-12 mt-0">
                                            <div class="card card-xxl-stretch mb-5 bg-lighten rounded-3 border border-gray-300">
                                                <div class="card-body">                                                       
                                                        <div class="fv-row mb-7 row">
                                                            <div class="col-lg-6">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_role_campaign'">
                                                                    <span>{{ $t('Sales Role / Campaign') }}</span>
                                                                </label>
                                                                <Select2 v-model="form.campaign_id" :options="campaignList" name="sales_role_campaign" v-custom :settings="{ width: '100%' }"/>
                                                            </div>
                                                            <div class="col-lg-6">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'sales_channel'">
                                                                    <span> {{ $t('Sales Channel') }}</span>
                                                                </label>
                                                                <Select2 v-model="form.sales_channel_id" :options="salesChannelList" name="sales_channel" v-custom :settings="{ width: '100%' }"/>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-12 mt-0">
                                            <div class="d-grid gap-2">
                                                <button class="btn btn-primary mb-4 fw-boldest" type="submit" @click="changeView(2)" :disabled="form.account_id == null">
                                                    <span class="svg-icon svg-icon-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                            viewBox="0 0 24 24" fill="none">
                                                            <path
                                                                d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                                                                fill="currentColor"></path>
                                                            <path opacity="0.3"
                                                                d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                                                                fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                    {{ $t('Start Policy') }}
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                
                                <div class="d-flex flex-column flex-row-auto col-lg-3">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch border border-gray-300 mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300" v-if="!this.isContactInfoVisible"> 
                                        <div class="card-header">
                                            <div class="card-title">
                                                <h4 class="text-gray-800">
                                                    {{ $t('Recently Used Accounts') }}
                                                </h4> 
                                            </div>
                                            <div class="card-toolbar">                                        
                                                <i class="bi bi-info-circle text-primary fs-3 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"
                                                    v-if="this.form.account_id !== null && this.form.account_id !== '' && this.form.account_id != undefined">
                                                </i>
                                            </div>
                                        </div>
                                        <div class="card-body p-5" v-if="accountLatestList">
                                            <div class="scroll-y me-n5 pe-5 h-300px h-xl-auto mh-500px">
                                                <div v-for="(item, index) in accountLatestList" v-bind:key="item">
                                                    <div class="d-flex flex-stack py-2">
                                                        <div class="d-flex align-items-center">
                                                            <div class="symbol symbol-40px symbol-circle cursor-pointer">
                                                                <span v-if="this.form.account_id !== item.id" class="symbol-label fs-6 fw-bolder">
                                                                    <a href="#" class="btn btn-sm btn-icon text-hover-primary btn-circle border border-1 border-gray-200" 
                                                                        @click="onOpenAccountReport(item)" data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                        <i class="fa fa-eye text-gray-600 fs-2"></i>
                                                                    </a>
                                                                </span>

                                                                <span v-if="this.form.account_id == item.id" class="svg-icon svg-icon-success svg-icon-3x">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"/>
                                                                        <path d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                            </div>
                                                            <a class="ms-4 cursor-pointer" @click="getAccountDetail(item.id)">
                                                                <span class="fs-6 fw-bold text-gray-800 text-hover-primary mb-2 cursor-pointer">{{item.name}}</span>
                                                                <div class="fw-bold fs-7 text-muted cursor-pointer">{{item.account_type_name}}
                                                                    <span class="h-20px border-gray-400 border-start mx-3 mt-3"></span>
                                                                    <span class="fw-bold fs-7 text-muted cursor-pointer">{{item.local_balance}}</span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="separator separator-dashed my-3" v-if="index !== accountLatestList.length - 1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card border border-gray-300" v-if="this.isContactInfoVisible">
                                        <div class="card-header">
                                            <div class="card-title">
                                                <h4 class="text-gray-800">
                                                    {{ $t('Account Info') }}
                                                </h4> 
                                            </div>
                                            <div class="card-toolbar m-0 p-0">
                                                <i class="bi bi-x-lg fs-5 ms-3 cursor-pointer" @click="this.isContactInfoVisible = !this.isContactInfoVisible;"></i>
                                            </div>
                                        </div>
                                        <AccountInfo :selected-account-info="this.selectedAccountInfo"></AccountInfo>  
                                    </div>
                                </div>
                            </div>

                            <div class="row" v-if="form.activeView == 2">
                                <div class="d-flex flex-column flex-row-auto col-lg-12"> 
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                        <div class="card-header min-h-40px rounded-2 rounded-bottom-0 bg-gray-gradient">
                                            <div class="card-title">
                                                <h3>{{ $t('Insurer Account') }}</h3>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-lg-4">
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row">
                                                        <span class="fs-2 fw-bolder text-gray-800">{{selectedAccountInfo.name}}<br>
                                                        <span class="text-gray-700">{{selectedAccountInfo.customer_account_number}}</span></span> 
                                                        <div class="d-flex justify-content-end ms-3">
                                                            <div class="symbol symbol-100px cursor-pointer"  data-bs-toggle="modal" data-bs-target="#kt_modal_account_report_selection" @click="onOpenAccountReport()">
                                                                <div class="symbol-label fw-bold bg-light border text-hover-primary">
                                                                    <i class="fa fa-eye text-gray-600 fs-2x"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                        <span class="fs-6 fw-bolder text-gray-700 mt-2">
                                                            {{selectedAccountInfo.customer_address}}
                                                        </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                        <span class="fs-6 text-gray-700 fw-bold"> {{selectedAccountInfo.customer_city}} </span>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_district}} </span>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_country}} </span>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_postcode}} </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                        <a href="{{selectedAccountInfo.customer_email}}" class="fw-bold fs-6"> {{selectedAccountInfo.customer_email}} </a>
                                                        <span class="fs-6 text-gray-700 fw-bold ms-5"> {{selectedAccountInfo.customer_mobile_phone}} </span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row  mt-3">
                                                       <span class="fs-6 text-gray-700 fw-bolder"> {{getInsuranceTypeName(form.insurance_type)}} </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                        <span class="fs-6 text-gray-700 fw-bolder">  {{getMainBranchName(form.main_branch_id)}} </span>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-start flex-equal order-3 fw-row mt-2">
                                                        <span class="fs-6 text-gray-700 fw-bolder"> {{getPolicyBranchName(form.policy_branch_id)}} </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-12">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300"> 
                                        <div class="card-header min-h-40px rounded-2 rounded-bottom-0 bg-gray-gradient">
                                            <div class="card-title">
                                                <h3>{{ $t('Policy Card Detail') }} </h3>
                                            </div>
                                        </div>
                                        <div class="card-body pt-2">
                                            <div class="row row-cols-1 row-cols-sm-2 rol-cols-md-1 row-cols-lg-2">
                                                <div class="col-lg-2 col-md-4 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'regulation_date'">
                                                            {{ $t('Regulation Date') }}
                                                        </label>
                                                        <Datepicker name="regulation_date" :required="this.$checkIfDateFieldRequired ('regulation_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.regulation_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :minDate="userData.last_transaction_date" :enableTimePicker="false" />
                                                        <small class="text-info" v-if="userData.last_transaction_date !== null && userData.last_transaction_date !== undefined && userData.last_transaction_date !== ''">
                                                            {{ $t('The last transaction date is ') }} {{previewDate(userData.last_transaction_date)}}. {{ $t('Dates before this date are closed for transaction.') }}
                                                        </small>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-4 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'start_date'">
                                                            {{ $t('Start Date') }}
                                                        </label>
                                                        <Datepicker name="start_date" @blur="getYearList()" :required="this.$checkIfDateFieldRequired ('start_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false"/>
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-4 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'end_date'">
                                                            {{ $t('End Date') }}
                                                        </label>
                                                        <Datepicker name="end_date" :required="this.$checkIfDateFieldRequired ('end_date')" textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" format="dd.MM.yyyy" previewFormat="dd.MM.yyyy" autoApply :clearable="false" :enableTimePicker="false" />
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-4 col-sm-6">
                                                    <div class="fv-row mb-1">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'policy_no'">
                                                            {{ $t('Policy No') }}
                                                        </label>
                                                        <input type="text" class="form-control fw-boldest text-black-600" name="policy_no" v-custom v-model="form.policy_no">
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-4 col-sm-6">
                                                    <div class="fv-row mb-1">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'renewal_no'">
                                                            {{ $t('Renewal No') }}
                                                        </label>
                                                        <input type="text" class="form-control fw-boldest text-black-600" name="renewal_no" v-custom v-model="form.renewal_no">
                                                    </div>
                                                </div>
                                                <div class="col-lg-2 col-md-4 col-sm-6">
                                                    <div class="fv-row mb-1 fv-plugins-icon-container">
                                                        <label class="fs-6 fw-bold form-label mt-3" v-tag="'year'">
                                                            {{ $t('Year') }}
                                                        </label>
                                                        <Select2 v-model="form.year" :options="yearList" name="year" v-custom :settings="{ width: '100%' }"/> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-column flex-row-auto col-lg-12">
                                    <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten rounded-3 border border-gray-300">
                                        <div class="card-header">
                                            <div class="card-title">
                                                {{ $t('Insurance Vendors') }}
                                            </div>
                                        </div>
                                        <div class="card-body"> 
                                            <div class="card card-xxl-stretch card-bordered">
                                                <div class="p-5">
                                                    <div class="d-flex flex-stack flex-wrap mb-5">
                                                        <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                            <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                                data-bs-target="#kt_modal_add_vendor" @click="this.openAddVendorModal()"
                                                                data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                                <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                        viewBox="0 0 24 24" fill="none">
                                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                            transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                            fill="currentColor">
                                                                        </rect>
                                                                    </svg>
                                                                </span>
                                                                {{ $t('Add') }}
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="deleteVendorItem()" 
                                                                :disabled="isButtonDisabled == null || isButtonDisabled == undefined">
                                                                <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                                {{ $t('Remove') }}
                                                            </button>
                                                            <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3" @click="deleteAllVendors()" 
                                                                :disabled="vendorDefaultList.length == 0">
                                                                <span class="svg-icon svg-icon-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                                    </svg>
                                                                </span>
                                                                {{ $t('Remove All (!)') }}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                        <div class="table-responsive">
                                                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                        key-expr="id" 
                                                                        :data-source="vendorDefaultList" 
                                                                        :allow-column-reordering="true"  
                                                                        :allow-column-resizing="true" 
                                                                        :column-auto-width="true" 
                                                                        :row-alternatin-enabled="true"
                                                                        :hover-state-enabled="true" 
                                                                        :show-row-lines="true" > 

                                                                    <DxFilterRow :visible="true"/>
                                                                    <DxHeaderFilter :visible="true"/>
                                                                    <DxGroupPanel :visible="false"/>
                                                                    <DxPaging :page-size="100"/>
                                                                    <DxPager :visible="true" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                                                    <DxSearchPanel :visible="true"/>  
                                                                    
                                                                    <DxColumn data-field="isSelected" caption="#" :width="100" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                                    <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template"/>
                                                                    <DxColumn data-field="vendor_name" :caption="$t('Name')" cell-template="vendor_name-cell-template"/> 
                                                                    
                                                                    <template #is-selected-cell-template="{ data }"> 
                                                                        <span>
                                                                            <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center">
                                                                                <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectVendorItem(data.data)" />
                                                                            </div>
                                                                        </span>
                                                                    </template>
                                                                    <template #account_id-cell-template="{ data }"> 
                                                                        <span>
                                                                            {{ data.data.account_id }}
                                                                        </span>
                                                                    </template>
                                                                    <template #vendor_name-cell-template="{ data }"> 
                                                                        <span>
                                                                            {{ data.data.vendor_name }}
                                                                        </span>
                                                                    </template>
                                                            </DxDataGrid>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                                
                                <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(48,'I')">
                                    <div class="d-grid gap-2">
                                        <button class="btn btn-success btn-lg mb-4" type="submit" @click="createPolicy();"  data-kt-indicator="on"> 
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span> 
                                            <strong v-if="!this.isInvoiceProgressing"> {{ $t('Save Policy Card') }}
                                            </strong> 
                                            <span class="indicator-progress" v-if="this.isInvoiceProgressing">
                                                {{ $t('Saving Policy. Please wait...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!-- Add New Customer -->
        <div class="modal fade" id="kt_modal_add_contact" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
			<AddNewContact ref="AddNewContact"/>
		</div>
        <!-- Search Account Modal -->
        <div class="modal fade" id="kt_search_account_modal" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <SearchAccountWithSelect ref="SearchAccountWithSelect" :account-type="1"/>
        </div>
        <!-- Statement Modal  -->
        <div class="modal fade" id="kt_modal_account_report_selection" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountReports ref="AccountReports" :account-name="form.account_name" :account-id="form.account_id" :account-type="form.account_type" />
        </div> 
        <!-- Add Vendor -->
        <div class="modal fade" id="kt_modal_add_vendor" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary">
                        <h2 class="modal-title fw-bolder col-md-10 text-white">{{ $t('Add Vendor') }}</h2>  
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body pt-0" :class="{'overlay overlay-block': this.isItemSaving == true}">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                            key-expr="id" 
                                            :data-source="vendorSelectionList" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true" 
                                            @exporting="this.$onExporting($event , 'Vendor-defaults-list')" >

                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="true"/>      
                                            <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                            <DxPaging :page-size="10000"/>
                                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                            <DxSearchPanel :visible="true"/>

                                            <DxColumn data-field="isSelected" :width="80" caption="" header-cell-template="is-selected-header" cell-template="is_selected-cell-template" :allow-filtering="false" alignment="center" :allow-exporting="false"/>
                                            <DxColumn data-field="account_id" :caption="$t('Code')" cell-template="account_id-cell-template"/> 
                                            <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/> 

                                            <!-- Header Cell Templates -->
                                            <template #is-selected-header>
                                                <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" v-model="isSelectedAll" @change="onSelectAll()"> 
                                                </div>
                                            </template>

                                            <!-- Cell Templates -->
                                            <template #is_selected-cell-template="{ data }"> 
                                                <span>
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" />
                                                    </div>
                                                </span>
                                            </template>
                                            <template #account_id-cell-template="{ data }">
                                                <span>
                                                    {{ data.data.account_id }}
                                                </span>
                                            </template>
                                            <template #name-cell-template="{ data }">
                                                <span>
                                                    {{ data.data.name }}
                                                </span>
                                            </template>
                                        </DxDataGrid> 
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="card card-flush mt-5" id="kt_add_user_button">
                                            <div class="d-grid gap-2"> 
                                                <button type="button" class="btn btn-light-success border border-success btn-lg"
                                                    @click="addVendorItem()">
                                                    <span class="indicator-label">{{ $t('Add Vendor to List') }}</span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isItemSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from 'moment';
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel } from 'devextreme-vue/data-grid';
// import { DxSelectBox } from 'devextreme-vue/select-box';
import AddNewContact from '../Transaction/Modals/AddNewContact.vue';
import SearchAccountWithSelect from '../Accounts/Modals/SearchAccountWithSelect.vue';

 
export default {
  name: "PolicyCreate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSearchPanel, AddNewContact, SearchAccountWithSelect
  },
  data() {
    return {
        userData: this.$getUser(),
        activeTab: 'General',
        pageSize: 20,
        pageIndex: 0,
        dropDownOptions: { width: 600 },
        searchbar: '',
        processList:[{ id: 1, text: 'Standard'}, { id: 2, text: 'Fixed Asset'}], 
        insuranceTypeList: [
          { id: 1, text: 'Insurance'},
          { id: 2, text: 'Reinsurance'},
          { id: 3, text: 'Coinsurance'}
        ],
        policyTypeList: [
          { id: 1, text: 'Main Policy'},
          { id: 2, text: 'Endorsement Policy'}
        ],
        endorsementTypeList: [
          { id: 1, text: 'Main Policy'},
          { id: 0, text: 'Select an option'},
          { id: 2, text: 'Coverage Addition'},
          { id: 3, text: 'Coverage Reduction'},
          { id: 4, text: 'Coverage Cancellation'},
          { id: 5, text: 'Change of Content'},
        ],
        accountTypeList: [
            { id: 1, text: 'Customer Account' },
            { id: 2, text: 'Supplier Account' },
        ],
        salesChannelList: [],
        campaignList: [],
        paymentList: [],
        shippingList: [],
        branchList: [],
        accountLatestList: [],
        stockPricesList: [],
        mainBranchList: [],
        policyBranchList: [{ id: '-1', text: 'Select an option'}],
        localCurrency: null,
        modalType: 'addItem',
        isInvoiceProgressing: 'off',
        invoiceNumberPattern: null,
        totalsCardView: 'processTotal',
        grandTotalsCardView: 'processTotal',
        stock_qr_code: null,
        isContactInfoVisible: false,
        isStockItemSaving: false,
        isModalButtonDisabled: false,
        form: {            
            activeView: 1,
            policy_card_id: null,
            account_id: null,
            account_name: null,
            account_type: 0,
            sales_channel_id: '-1',
            campaign_id: '-1',
            policy_no: null,
            renewal_no: '0',
            insurance_type: 1,
            regulation_date: moment(),
            start_date: moment(),
            end_date: moment().add(1, 'year'),
            policy_type: 1,
            endorsement_type: 1,
            policy_branch_id: '-1',
            main_branch_id: '-1',
            main_producer_id: '-1',
            vendor_id: '-1',
            year:  moment().year()
        },
        selectedAccountInfo: {},
        menuSelection: '/PoliciesHelppage',
        iconView : false,
        vendorDefaultList: [],
        vendorSelectionList: [],
        isSelectedAll: false,
        isItemSaving: false,
        yearList: []
    };
  },
  computed: {
    previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelector() {
        return this.vendorDefaultList.length > this.pageSize;
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.vendorDefaultList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    endorsementTypeFilteredList() {
        if(this.form.policy_type == 1) {
            return this.endorsementTypeList.filter(p => { 
                return p.id == 1;
            });
        } else {            
            return this.endorsementTypeList.filter(p => { 
                return p.id !== 1;
            });
        }
        
    },
    isButtonDisabled() {
        return this.vendorDefaultList.find(item => item.isSelected == true );
    },
    getMainBranchName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          const element = this.mainBranchList.find(element => {
            return element.id == value
          });

          if (element !== null && element !== '' && element !== undefined) {
            return element.name;
          }
          else return null;
        }
      }
    },
    getPolicyBranchName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          const element = this.policyBranchList.find(element => {
            return element.id == value
          });

          if (element !== null && element !== '' && element !== undefined) {
            return element.name;
          }
          else return null;
        }
      }
    },
    getInsuranceTypeName(){
      return (value)=>{
       if (value !== null && value !== '' && value !== undefined) {
          return this.insuranceTypeList.find(element => {
            return element.id == value
          }).text;
        }
      }
    }
  },
  methods: { 
    getData() {
        this.$Progress.start(); 
        this.$setSessionStorage('refreshList', true); 
        this.isInvoiceProgressing = false;
        this.accountLatestList = [];        
        this.$removeSessionStorage('tmp_session_id');             
        const params = {
            session_id : this.$getUser().session_id,
            account_type : 1 // Customer: 1
        };
        axios.post('api/Invoice/GetLatestUsedAccounts', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.accountLatestList = response.data;
                this.getDetailLists();
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    getDetailLists() {
        const parametersTwo = { 
            session_id: this.$getUser().session_id,
            list_name: ["campaign", "sales_channel"]
        };
        const parametersThree = {
            session_id: this.$getUser().session_id,
            list_type: 1
        };
        const parametersFour = {
            session_id: this.$getUser().session_id,            
            supplierAccounts: [{account_type: 1, account_name: "producer"}],
        };
        
        const requestTwo =  axios.post('/api/Helper/GeneralLists', parametersTwo, {'Content-Type': 'application/json'}); 
        const requestThree =  axios.post('/api/Helper/GetPolicyLists', parametersThree, {'Content-Type': 'application/json'});
        const requestFour =  axios.post('/api/GetCompanyAccounts/GetMultiPolicyAccounts', parametersFour, {'Content-Type': 'application/json'});
        
        axios.all([requestTwo, requestThree, requestFour]).then(axios.spread((...responses) => {            
            this.campaignList = responses[0].data.campaign;
            this.salesChannelList = responses[0].data.sales_channel;
            this.localCurrency = this.$getUser().currency_code;
            this.branchList.forEach((value) => {
                if(value.is_default) {
                    this.form.branch_id = value.id;
                }
            });            
            this.campaignList.unshift({ id: '-1', text: 'Select an option'});
            this.campaignList.forEach((value) => {
            if(value.is_default){
                this.form.campaign_id = value.id;
                }
            });
            this.salesChannelList.unshift({ id: '-1', text: 'Select an option'}); 
            this.salesChannelList.forEach((value) => {
                if(value.is_default){
                    this.form.sales_channel_id = value.id;
                }
            });
            this.mainBranchList = responses[1].data;
            this.mainBranchList.unshift({ id: '-1', text: 'Select an option'});
            this.mainBranchList.forEach((value) => {
                if(value.is_default) {
                    this.form.main_branch_id = value.id;
                }
            });
            this.producerList = responses[2].data.producer;
            this.producerList.unshift({ id: '-1', text: 'Select an option'});
            this.getYearList();

            this.$Progress.finish();
        }))
        .catch(errors => { 
            console.error(errors); 
            this.$Progress.finish();
        });
    },
    getYearList() {
        this.yearList = [];
        let x = -1;
        let thisYear =  moment(this.form.start_date).year();
        for (let i = 1; i <= 3; i++) {
            this.yearList.push(thisYear + x);
            x++;
        }
        this.form.year = thisYear;
        this.form.end_date = moment(this.form.start_date).add(1, 'year');
    },
    getPolicyParentList(isChanged) {
        this.form.policy_branch_id = '-1';
        const params = {
            session_id: this.$getUser().session_id,
            list_type: 2, // Policy Sub Branch: 2 
            parent_id: this.form.main_branch_id
        };
        axios.post("/api/Helper/GetPolicyParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.policyBranchList = response.data;
            this.policyBranchList.unshift({ id: '-1', text: 'Select an option'});
            if(!isChanged) {
                this.policyBranchList.forEach((value) => {
                    if(value.is_default) {
                        this.form.policy_branch_id = value.id;
                    }
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    getAccountDetail(id) {
        if (id == '-1' || id == null || id == undefined) return;
        const params = {
            id: id,
            session_id: this.$getUser().session_id
        }; 
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {
            response.data.name = this.$unlock(response.data.name);
            response.data.customer_account_number = this.$unlock(response.data.customer_account_number);
            response.data.customer_address = this.$unlock(response.data.customer_address);
            response.data.customer_city = this.$unlock(response.data.customer_city);
            response.data.customer_district = this.$unlock(response.data.customer_district);
            response.data.customer_country = this.$unlock(response.data.customer_country);
            response.data.customer_postcode = this.$unlock(response.data.customer_postcode);
            response.data.customer_email = this.$unlock(response.data.customer_email);
            response.data.customer_mobile_phone = this.$unlock(response.data.customer_mobile_phone);
            this.selectedAccountInfo = response.data;
            this.form.account_id = this.$unlock(response.data.id);
            this.form.account_name = this.$unlock(response.data.name);
            this.form.account_type = response.data.account_type;
        })
        .catch(function () {
        });  
    },
    getVendorAccounts() {
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id: this.form.account_id,
            main_branch_id: this.form.main_branch_id == '-1' ? null : this.form.main_branch_id,
            policy_branch_id: this.form.policy_branch_id == '-1' ? null : this.form.policy_branch_id
        };  
        axios.post('/api/Insurance/GetCustomerVendorsByBranch', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.vendorDefaultList = response.data;
                if(this.vendorDefaultList.length > 0){
                    this.$setSessionStorage('tmp_session_id', this.vendorDefaultList[0].tmp_session_id);
                } 
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    changeView(activeView) {
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.form.activeView = activeView;
        this.getVendorAccounts();
    },
    onClickCancel() {
        this.$swal.fire({
            title: 'You have not saved yet. Are you sure you want to cancel?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) { 
                // window.location.reload();
                this.$router.push({ path: '/PolicyCreate' }).then(() => { this.$router.go(0) });
            }
        });
    },
    createPolicy(){ 
        this.$checkSelect2Validation('poliform');
        if(!document.forms['poliform'].reportValidity()){
            return;
        }
        this.$swal.fire({
            title: "Are you sure you want to save this Policy?",
            confirmButtonColor: "#50cd89",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "SAVE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                if(this.form.start_date == null || this.form.start_date == '' || this.form.start_date == undefined) {
                    this.$swal("Warning", "Start Date must be valid..!", 'info'); return;
                }
                if(this.form.end_date == null || this.form.end_date == '' || this.form.end_date == undefined) {
                    this.$swal("Warning", "End Date must be valid..!", 'info'); return;
                }
                if(this.form.regulation_date == null || this.form.regulation_date == '' || this.form.regulation_date == undefined) {
                    this.$swal("Warning", "Regulation Date must be valid..!", 'info'); return;
                }
                this.$Progress.start();
                this.isInvoiceProgressing = true;
                const that = this;
                const params = { 
                    session_id: this.$getUser().session_id,
                    account_id: this.form.account_id,
                    sales_channel_id: this.form.sales_channel_id !== '-1' ? this.form.sales_channel_id : null,
                    campaign_id: this.form.campaign_id !== '-1' ? this.form.campaign_id : null,
                    policy_no: this.form.policy_no,
                    renewal_no: this.form.renewal_no,
                    insurance_type: this.form.insurance_type,
                    regulation_date: this.form.regulation_date,
                    start_date: this.form.start_date,
                    end_date: this.form.end_date,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    main_branch_id: this.form.main_branch_id !== '-1' ? this.form.main_branch_id : null,
                    policy_branch_id: this.form.policy_branch_id !== '-1' ? this.form.policy_branch_id : null,
                    main_producer_id: this.form.main_producer_id !== '-1' ? this.form.main_producer_id : null,
                    year: this.form.year
                }; 
                // Timezone bilgisini tarihten yok ediyoruz
                params.regulation_date = moment(params.regulation_date).format('YYYY-MM-DD[T]HH:mm:ss');
                params.start_date = moment(params.start_date).format('YYYY-MM-DD[T]HH:mm:ss');
                params.end_date = moment(params.end_date).format('YYYY-MM-DD[T]HH:mm:ss');

                axios.post('api/Insurance/CreatePolicy', params, {'Content-Type': 'application/json'})
                    .then((response) => { 
                        if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                            this.$swal("Success", "Policy saved succesfully..!", 'success');   
                            this.$setSessionStorage('record_id', response.data);
                            this.$router.push({ name: 'PolicyUpdate' });
                        } 
                        this.$Progress.finish(); 
                    })
                    .catch(function () { 
                        this.$Progress.finish(); 
                        new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
                    })
                    .finally(function () { 
                        setTimeout(() => {
                            that.isInvoiceProgressing = false; 
                            that.$Progress.finish();
                        }, 500);
                    });  
                }
        });
    },
    openCreateContactModal() {
        this.$refs.AddNewContactButton.click();
        this.$refs.AddNewContact.getData();
        this.$refs.AddNewContact.createContactForm.account_type = 1;
        this.$refs.AddNewContact.isDisabledAccountType = true;
    },
    onSuccessNewAccount(account_id) {
        this.getAccountDetail(account_id);
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/PoliciesHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    openSearchModal(searchText) {
        this.$refs.SearchAccountWithSelect.getData(1, 0, 0, searchText, true);
    },
    onChangePolicyType() {
        this.form.endorsement_type = this.form.policy_type == 1 ? 1 : 0;
    },
    openAddVendorModal(){
        this.isItemSaving = false;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            supplier_account_type: 2
        };  
        axios.post('/api/GetCompanyAccounts/GetVendorAccountsForSelection', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.vendorSelectionList = response.data;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    addVendorItem(){
        this.selectedAccounts = [];
        this.vendorSelectionList.forEach((value) => {
            if(value.isSelected){
                this.selectedAccounts.push(value.id);
            }
        }); 
        if (this.selectedAccounts.length == 0) {            
            this.$swal("Failed", " At least one option must be selected..!", 'warning' );
            return;
        }
        this.isItemSaving = true;
        const parameters = {
            session_id: this.$getUser().session_id,
            tmp_session_id: this.$getSessionStorage('tmp_session_id'),
            accountList: this.selectedAccounts
        };
        const that = this;
        axios.post('/api/Insurance/AddVendorItem', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                this.vendorDefaultList = response.data;
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                this.$refs.closeModal.click();
            }            
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        }) 
        .catch(function () {
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        })
        .finally(function () { 
            setTimeout(() => {
                that.isItemSaving = false;
            }, 500);
        });
    },
    deleteVendorItem(){
        let vendorId = null;
        this.vendorDefaultList.forEach((item) => {
            if (item.isSelected) {
                vendorId = item.vendor_id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    vendor_id: vendorId,
                    is_delete_all: 0
                }; 
                axios.post('/api/Insurance/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorDefaultList = response.data;
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    deleteAllVendors(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    is_delete_all: 1,
                    vendor_id: null
                }; 
                axios.post('/api/Insurance/DeleteVendorItem', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.vendorDefaultList = [];
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    onSelectVendorItem(item) { 
        this.vendorDefaultList.forEach((value) => {
            if (item.id !== value.id) { 
                value.isSelected = false;
            }
        });
    },
    onSelectAll() {
        this.vendorSelectionList.forEach((value) => {
            value.isSelected = this.isSelectedAll;
        });
    },
    onOpenAccountReport(item){
        let accountInfo = {
            id: (item !== null && item !== undefined && item !== '') ? item.id : this.form.account_id, 
            name: (item !== null && item !== undefined && item !== '') ? item.name : this.form.account_name
        };
        this.$refs.AccountReports.getData(accountInfo);
        this.$refs.AccountReports.setModalCriteriasToDefault();
    },
    onClickSearchButton() {
        this.$refs.searchAccountButton.click();
    }
  },
  created() { 
    this.helpPageViewIcon();
    this.$setSessionStorage('refreshList', true);
    this.getData();
  }
};
</script>
<style scoped> 
.dx-texteditor.dx-editor-outlined {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    padding: 4px !important;
    color: black;
} 
</style>
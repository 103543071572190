<template> 
    <div> 
        <div class="d-flex flex-stack flex-wrap">
            <div class="page-title d-flex flex-column me-3 mt-3"> 
                <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('Update Stock & Service Account') }}</h1>  
                <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                    <li class="breadcrumb-item text-gray-600">
                        <a href="#" class="text-gray-600 text-hover-primary">
                            <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                        </a>
                    </li> 
                    <li class="breadcrumb-item text-primary">{{ $t('Accounts') }}</li>     
                    <li class="breadcrumb-item text-gray-600"><router-link :to="{ name: 'StockList' }">{{ $t('Stock & Service') }}</router-link></li>     
                    <li class="breadcrumb-item text-gray-600">{{ $t('Update Stock & Service Account') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon>
                    </li> 
                </ul> 
            </div>  
            <div class="d-flex align-items-center py-1">
                <router-link to="/StockList">
                    <button type="button" class="btn btn-light shadow-sm btn-arrow-left border border-gray-400 me-3">
                    <span class="ms-2 fs-7"> {{ $t('Return back to List') }}</span>
                    </button>
                </router-link> 
                &nbsp;
                <div class="dropdown" v-if="this.$isCredentialActive(16,'D')">
                    <button class="btn btn-light-info border border-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" id="stockActions" type="button">
                        <span class="svg-icon svg-icon-muted">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path opacity="0.3" d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z" fill="currentColor"/>
                            <path d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z" fill="currentColor"/>
                            </svg>
                        </span>
                        {{ $t('Actions') }}
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="stockActions"> 
                        <li>
                            <a class="dropdown-item fs-4 text-danger" href="#" @click="deleteItem()"><span class="svg-icon svg-icon-muted svg-icon-2 text-danger"><svg width="24" height="24" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"/>
                            <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"/>
                            <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"/>
                            </svg>
                            </span>{{ $t('Delete') }} </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="content flex-row-fluid mt-4" id="kt_content"> 
            <StatsBar :form-account="this.form"></StatsBar>
            <div class="card-body">
                <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6">
                    <li class="nav-item">
                        <a class="nav-link" :class="{'active': this.activeTab == 'Account'}" data-bs-toggle="tab" href="#kt_accounting_1" target="_self"
                        @click="this.activeTab = 'Account'">
                            <h4> {{$t('Stock & Service Account')}} </h4>
                        </a>
                    </li>
                    <li class="nav-item" v-if="this.$isModuleActive(3)"> 
                        <a class="nav-link" :class="{'active': this.activeTab == 'Documents'}" data-bs-toggle="tab" href="#kt_accounting_4" target="_self"
                        @click="this.activeTab = 'Documents', activateDocumentsSection()">
                            <h4> {{ $t('Documents') }} </h4>
                        </a>
                    </li>
                    <li class="nav-item" v-if="this.$isModuleActive(3)"> 
                        <a class="nav-link" :class="{'active': this.activeTab == 'StockImages'}" data-bs-toggle="tab" href="#kt_accounting_5" target="_self"
                        @click="this.activeTab = 'StockImages', getAccountImageData()">
                            <h4> {{ $t('Stock Images') }} </h4>
                        </a>
                    </li>
                    <li class="nav-item" v-if="form.is_bundle_product"> 
                        <a class="nav-link" :class="{'active': this.activeTab == 'BundleProducts'}" data-bs-toggle="tab" href="#kt_accounting_6" target="_self"
                        @click="this.activeTab = 'BundleProducts',activateBundleProductsSection()">
                            <h4> {{ $t('Product / Bundle') }} </h4>
                        </a>
                    </li>
                </ul>
            </div>
            <RequiredField></RequiredField>
            <div class="tab-content" id="myTabContent">
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_1" role="tabpanel" v-if="this.activeTab == 'Account'">
                    <form id="poliform" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">
                        <div class="row p-10">
                            <div class="row">
                                <div class="col-lg-12 mt-0">
                                    <div class="d-flex flex-row row">
                                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten border border-gray-300">
                                                <div class="card-body pb-0">
                                                    <div class="row">                  
                                                        <div class="fv-row mb-7 row">
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'product_code'">
                                                                    {{ $t('Product Code') }}
                                                                </label>
                                                                <input type="text" class="form-control" name="product_code" v-model="form.product_code" v-custom>
                                                            </div>
                                                            <div class="col-lg-12" v-if="isViewAccountId">
                                                                <label class="fs-6 fw-bold form-label mt-3" :class="{'required': isViewAccountId}" v-tag="'stock_code'">
                                                                    {{ $t('Stock & Service Code') }}
                                                                </label>
                                                                <input type="text" class="form-control" name="stock_code" v-model="form.account_id" v-custom>
                                                            </div>                                      
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'stock_name'">
                                                                    {{ $t('Stock & Service Name') }}
                                                                </label>
                                                                <input type="text" class="form-control" name="stock_name" v-model="form.name" v-custom>
                                                            </div> 
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'stock_group'">
                                                                    {{ $t('Stock & Service Group') }}
                                                                </label>
                                                                <Select2 v-model="form.stock_group_id" :options="stockGroupList" name="stock_group" v-custom :settings="{ width: '100%'}"/>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-5" for="isActive">
                                                                    {{ $t('Is Bundle Product?') }}
                                                                </label>
                                                                <div class="form-check mt-3 mb-2">
                                                                    <input class="form-check-input" type="checkbox" value="" id="is_bundle_product" v-model="form.is_bundle_product">
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex flex-column flex-row-auto col-lg-6">
                                            <div class="d-flex flex-column-fluid card card-xxl-stretch mb-5 mb-xxl-10 bg-lighten border border-gray-300">
                                                <div class="card-body pb-0">
                                                    <div class="row">                        
                                                        <div class="fv-row mb-6 row">
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'account_sub_type'">
                                                                    <span>{{ $t('Account Type') }}</span>
                                                                </label>
                                                                <Select2 v-model="form.account_sub_type" :options="accountSubTypeList" name="account_sub_type" v-custom :settings="{ width: '100%'}"/> 
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'product_group'">
                                                                    <span>{{ $t('Product Group') }}</span>
                                                                </label>
                                                                <Select2 v-model="form.product_group_id" :options="productGroupList" @select="onChangeProductGroup(true)" name="product_group" v-custom :settings="{ width: '100%'}"/>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'product_type'">
                                                                    <span>{{ $t('Product Type') }}</span>
                                                                </label>
                                                                <Select2 v-model="form.product_type_id" :options="productTypeList" @select="onChangeProductType(true)" name="product_type" v-custom :settings="{ width: '100%'}"/>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <label class="fs-6 fw-bold form-label mt-3" v-tag="'product_detail'">
                                                                    {{ $t('Product Detail') }}
                                                                </label>
                                                                <Select2 v-model="form.product_detail_id" :options="productDetailList" :settings="{ width: '100%' }" name="product_detail" v-custom/>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body">                     
                                            <div class="fv-row row">
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'unit_1'">
                                                        <span>{{ $t('Unit') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.stock_unit_id" :options="unitTypeList" :settings="{ width: '100%' }" name="unit_1" v-custom/> 
                                                </div>
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'unit_2'">
                                                        <span>{{ $t('Unit 2') }}</span>
                                                    </label>
                                                    <Select2 v-model="form.stock_unit_2_id" :options="unitType2List" :settings="{ width: '100%' }" name="unit_1" v-custom/> 
                                                </div>
                                                <div class="col-lg-4">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'unit_2'">
                                                        <span>{{ $t('Critical Stock Quantity') }}</span>
                                                    </label>
                                                    <input type="text" class="form-control" v-model="form.critical_stock_quantity" name="critical_stock_quantity" v-custom>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="p-5">
                                            <div class="d-flex flex-stack flex-wrap mb-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm  btn-light btn-active-success me-3" data-bs-toggle="modal" ref="btnAddItem"
                                                        data-bs-target="#kt_modal_add_price" @click="openPriceModal('addPrice')"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Add Price') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm  btn-light btn-active-primary me-3" data-bs-toggle="modal" ref="btnEditItem"
                                                        data-bs-target="#kt_modal_add_price" @click="openPriceModal('editPrice')" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                </svg>
                                                        </span>
                                                        {{ $t('Edit') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm  btn-light btn-active-warning me-3" @click="deletePrice()" :disabled="isItemButtonDisabled == null || isItemButtonDisabled == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm  btn-light btn-active-danger me-3" @click="deleteAllPrices()">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove All (!)') }}
                                                    </button>
                                                </div>
                                            </div>
                                            <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div class="table-responsive">
                                                        <DxDataGrid id="gridContainer" :show-borders="true"
                                                            key-expr="id" :data-source="stockCurrencyList" 
                                                            :allow-column-reordering="true"  
                                                            :allow-column-resizing="true" 
                                                            :column-auto-width="true" 
                                                            :row-alternatin-enabled="true"
                                                            :hover-state-enabled="true" 
                                                            :show-row-lines="true" @exporting="this.$onExporting($event , 'Stocks-update')"> 
                                                            <DxFilterRow :visible="true"/>
                                                            <DxHeaderFilter :visible="true"/>
                                                            <DxGroupPanel :visible="true"/>
                                                            <DxExport :enabled="false" :formats="['xlsx','pdf']" :allow-export-selected-data="false"/>
                                                            <DxPaging :page-size="10"/>
                                                            <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="showPageSizeSelector" :show-info="true" :show-navigation-buttons="true"/>
                                                            <DxScrolling
                                                                mode="virtual"
                                                                row-rendering-mode="virtual"
                                                            />
                                                            <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                            <DxColumn data-field="currency" :caption="$t('Currency')" cell-template="currency-cell-template"/>
                                                            <DxColumn data-field="price_type_name" :caption="$t('Price Type')" header-cell-template="price-type-header" cell-template="price_type_name-cell-template"/> 
                                                            <DxColumn data-field="sales_price" :sorting-method="this.$numericSort" :caption="$t('Sales Price')" alignment="right" header-cell-template="sales-price-header" cell-template="sales_price-cell-template"/> 
                                                            <DxColumn data-field="sales_tax_amount" :sorting-method="this.$numericSort" :caption="$t('% Sales VAT')" alignment="right" header-cell-template="sales-vat-header" cell-template="sales_tax_amount-cell-template"/> 
                                                            <DxColumn data-field="purchase_price" :sorting-method="this.$numericSort" :caption="$t('Purchase Price')" alignment="right" header-cell-template="purchase-price-header" cell-template="purchase_price-cell-template"/> 
                                                            <DxColumn data-field="purchase_tax_amount" :sorting-method="this.$numericSort" :caption="$t('% Purchase VAT')" alignment="right" header-cell-template="purchase-vat-header" cell-template="purchase_tax_amount-cell-template"/> 
                                                            <DxColumn data-field="sales_vat_status_id" :caption="$t('Sales VAT Status')" header-cell-template="sales-vat-status-header" alignment="left" cell-template="sales_vat_status_id-cell-template"/> 
                                                            <DxColumn data-field="purchase_vat_status_id" :caption="$t('Purchase VAT Status')" header-cell-template="purchase-vat-satus-header" alignment="left" cell-template="purchase_vat_status_id-cell-template"/>   

                                                            <template #price-type-header>
                                                                <div>{{ $t('Price') }}</div><div>{{ $t('Type') }}</div>
                                                            </template>
                                                            <template #sales-price-header>
                                                                <div>{{ $t('Sales') }}</div><div>{{ $t('Price') }}</div>
                                                            </template>
                                                            <template #sales-vat-header>
                                                                <div>% {{ $t('Sales') }}</div><div>{{ $t('Vat') }}</div>
                                                            </template>
                                                            <template #purchase-price-header>
                                                                <div>{{ $t('Purchase') }}</div><div>{{ $t('Price') }}</div>
                                                            </template>
                                                            <template #purchase-vat-header>
                                                                <div>% {{ $t('Purchase') }}</div><div>{{ $t('Vat') }}</div>
                                                            </template>
                                                            <template #sales-vat-status-header>
                                                                <div>{{ $t('Sales Vat') }}</div><div>{{ $t('Status') }}</div>
                                                            </template>
                                                            <template #purchase-vat-satus-header>
                                                                <div>{{ $t('Purchase Vat') }}</div><div>{{ $t('Status') }}</div>
                                                            </template>

                                                                <template #is-selected-cell-template="{ data }"> 
                                                                    <span>
                                                                        <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                            <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectItem(data.data)" />
                                                                        </div>
                                                                    </span>
                                                                </template>
                                                                <template #currency-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.currency }}
                                                                    </span>
                                                                </template>
                                                                <template #price_type_name-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.price_type_name }}
                                                                    </span>
                                                                </template>
                                                                <template #sales_price-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.sales_price }}
                                                                    </span>
                                                                </template>
                                                                <template #sales_tax_amount-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.sales_tax_amount }}
                                                                    </span>
                                                                </template>
                                                                <template #purchase_price-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.purchase_price }}
                                                                    </span>
                                                                </template>
                                                                <template #purchase_tax_amount-cell-template="{ data }"> 
                                                                    <span>
                                                                        {{ data.data.purchase_tax_amount }}
                                                                    </span>
                                                                </template>
                                                                <template #sales_vat_status_id-cell-template="{ data }"> 
                                                                    <div>
                                                                        <span v-if="data.data.sales_vat_status_id == 1">VAT (None)</span>
                                                                        <span v-if="data.data.sales_vat_status_id == 2">VAT (+)</span>
                                                                        <span v-if="data.data.sales_vat_status_id == 3">VAT (in)</span>
                                                                    </div>
                                                                </template>
                                                                <template #purchase_vat_status_id-cell-template="{ data }"> 
                                                                    <div>
                                                                        <span v-if="data.data.purchase_vat_status_id == 1">VAT (None)</span>
                                                                        <span v-if="data.data.purchase_vat_status_id == 2">VAT (+)</span>
                                                                        <span v-if="data.data.purchase_vat_status_id == 3">VAT (in)</span>
                                                                    </div>
                                                                </template> 

                                                                <DxSummary>
                                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                                </DxSummary>
                                                        </DxDataGrid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>            
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="p-5">
                                            <div class="d-flex flex-stack flex-wrap mb-5">
                                                <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                                    <button type="button" class="btn btn-sm btn-light btn-active-success me-3" data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_add_tracking_group"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end"
                                                        @click="onClickAddTrackingGroup('add')">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                                viewBox="0 0 24 24" fill="none">
                                                                <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                                    transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                                <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                                    fill="currentColor">
                                                                </rect>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Add Tracking Group') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-primary me-3" data-bs-toggle="modal"
                                                        data-bs-target="#kt_modal_add_tracking_group" @click="onClickAddTrackingGroup('edit')" :disabled="isStockTrackingItemButtonDisabled == null || isStockTrackingItemButtonDisabled == undefined"
                                                        data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                                        <span class="svg-icon svg-icon-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                    <path opacity="0.3" d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z" fill="currentColor"/>
                                                                    <path d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75954 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z" fill="currentColor"/>
                                                                </svg>
                                                        </span>
                                                        {{ $t('Edit') }}
                                                    </button>
                                                    <button type="button" class="btn btn-sm btn-light btn-active-warning me-3" @click="removeTrackingGroup()" :disabled="isStockTrackingItemButtonDisabled == null || isStockTrackingItemButtonDisabled == undefined">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove') }}
                                                    </button> 
                                                    <button type="button" class="btn btn-sm btn-light btn-active-danger me-3" @click="removeAllTrackingGroup()">
                                                        <span class="svg-icon svg-icon-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                                <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                            </svg>
                                                        </span>
                                                        {{ $t('Remove All (!)') }}
                                                    </button> 
                                                </div>
                                            </div>
                                            <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div class="table-responsive">
                                                    <DxDataGrid id="gridContainer" :show-borders="true" 
                                                                key-expr="id" 
                                                                :data-source="stockTrackingListItems" 
                                                                :allow-column-reordering="true"  
                                                                :allow-column-resizing="true" 
                                                                :column-auto-width="true" 
                                                                :row-alternatin-enabled="true"
                                                                :hover-state-enabled="true" 
                                                                :show-row-lines="true" > 

                                                            <DxFilterRow :visible="true"/>
                                                            <DxHeaderFilter :visible="true"/>
                                                            <DxGroupPanel :visible="false"/>
                                                            <DxPaging :page-size="10"/>
                                                            <DxPager :visible="false" :allowed-page-sizes="pageSizesStock" :show-page-size-selector="showPageSizeSelectorStock" :show-info="true" :show-navigation-buttons="true"/>
                                                            <DxScrolling
                                                                mode="virtual"
                                                                row-rendering-mode="virtual"
                                                            />                                                          
                                    
                                                            <DxColumn data-field="isSelected" caption="#" :allow-filtering="false" alignment="center" cell-template="is-selected-cell-template" :allow-exporting="false"/>
                                                            <DxColumn data-field="tracking_group_name" :caption="$t('Tracking Group')" cell-template="tracking_group_name-cell-template"/>
                                                            <DxColumn data-field="item_value" :caption="$t('Value')" cell-template="item_value-cell-template"/>
                                                            <DxColumn data-field="name" :caption="$t('Name')" cell-template="name-cell-template"/>
                                                            <DxColumn data-field="tracking_group_code" :caption="$t('Tracking Code')" cell-template="tracking_group_code-cell-template"/>
                                                            <DxColumn data-field="code" :caption="$t('Code')" cell-template="code-cell-template"/>
                                                            <DxColumn data-field="sales_channel_name" :caption="$t('Sales Channel')" cell-template="sales_channel_name-cell-template"/>
                                                            <DxColumn data-field="description" :caption="$t('Description')" cell-template="description-cell-template"/> 
                                                            <template #is-selected-cell-template="{ data }"> 
                                                                <span>
                                                                    <div class="form-check form-check-sm form-check-custom form-check-solid justify-content-center" v-if="data.data.id !== null && data.data.id !== ''">
                                                                        <input class="form-check-input" type="checkbox" v-model="data.data.isSelected" @change="this.onSelectStockTrackingItem(data.data)" />
                                                                    </div>
                                                                </span>
                                                            </template>
                                                            <template #tracking_group_name-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.tracking_group_name }}
                                                                </span>
                                                            </template>
                                                            <template #item_value-cell-template="{ data }">
                                                                <span>
                                                                    {{ data.data.item_value }}
                                                                </span>
                                                            </template>  
                                                            <template #name-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.name }}
                                                                </span>
                                                            </template>
                                                            <template #tracking_group_code-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.tracking_group_code }}
                                                                </span>
                                                            </template>
                                                            <template #code-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.code }}
                                                                </span>
                                                            </template>
                                                            <template #sales_channel_name-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.sales_channel_name }}
                                                                </span>
                                                            </template>
                                                            <template #description-cell-template="{ data }"> 
                                                                <span>
                                                                    {{ data.data.description }}
                                                                </span>
                                                            </template>

                                                            <DxSummary>
                                                                <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                            </DxSummary>
                                                    </DxDataGrid>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card card-xxl-stretch mb-6 bg-lighten border border-gray-300">
                                        <div class="card-body pb-0"> 
                                                <div class="fv-row mb-7">
                                                    <label class="fs-6 fw-bold form-label mt-3" v-tag="'notes'">
                                                        <span>{{ $t('Notes') }}</span>
                                                    </label>
                                                    <textarea class="form-control" name="notes" v-model="form.notes" v-custom></textarea>
                                                </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-0" v-if="this.$isCredentialActive(16,'U')">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-lg btn-primary mb-0" @click="this.saveData()" data-kt-indicator="on">
                                            <span class="svg-icon svg-icon-2"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            <path d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"/>
                                            </svg>
                                            </span>
                                            <strong v-if="!this.isProgressing"> {{ $t('Update Stock & Service') }} </strong>
                                                <span class="indicator-progress" v-if="this.isProgressing">
                                                    {{ $t('Saving Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_4" role="tabpanel" v-if="this.activeTab == 'Documents'">
                    <div class="row p-10">
                        <FileManager :location="1" :locationtype="7" :upload_source="4" ref="FileManagerComponent"></FileManager>
                    </div>
                </div>
                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_5" role="tabpanel" v-if="this.activeTab == 'StockImages'">
                    <div class="row">
                        <div class="card-header">
                            <div class="card-title">
                                <h2> {{ $t('Stock Images') }} </h2>
                            </div>
                            <div class="card-toolbar">
                                <a href="#" class="btn btn-light border border-2 btn-sm" @click="onStockAccountImageUploads()" data-bs-toggle="modal" data-bs-target="#kt_modal_stock_image_uploads" data-kt-menu-trigger="click">
                                    <i class="bi bi-pencil"></i> {{ $t('Upload and Edit Images') }}
                                </a>
                            </div>
                        </div>
                        <div class="card-body" v-if="accountImages.length == 0">
                            <div class="card-px text-center py-5">
                                <h2 class="fs-2x fw-bold mb-5">{{ $t('No stock images have been uploaded') }}.</h2>

                                <p class="text-gray-500 fs-4 fw-semibold mb-10">
                                    {{ $t('Click the "Upload Image" button to upload and edit images you have uploaded') }}.
                                </p>
                                <a href="#" class="btn btn-primary btn-sm" @click="onStockAccountImageUploads()" data-bs-toggle="modal" data-bs-target="#kt_modal_stock_image_uploads" data-kt-menu-trigger="click">
                                    {{ $t('Upload Image') }}
                                </a>
                            </div>
                            <div class="text-center px-4">
                                <img class="mw-100 mh-300px" alt="" src="assets/theme/media/illustrations/sketchy-1/5.png">
                            </div>
                        </div>

                        <div class="card-body" v-if="accountImages.length > 0">
                            <vueper-slides
                                ref="vueperslides1"
                                :touchable="false"
                                fade
                                :autoplay="false"
                                :bullets="false"
                                @slide="$refs.vueperslides2.goToSlide($event.currentSlide.index, { emit: false })"
                                fixed-height="500px">
                                <vueper-slide
                                    v-for="(slide, i) in accountImages"
                                    :key="i"
                                    :image="slide.url + '/' + slide.company_folder + '/' + slide.name">
                                </vueper-slide>
                            </vueper-slides>

                            <vueper-slides
                                class="no-shadow thumbnails mt-5"
                                ref="vueperslides2"
                                @slide="$refs.vueperslides1.goToSlide($event.currentSlide.index, { emit: false })"
                                :visible-slides="accountImages.length"
                                fixed-height="75px"
                                :bullets="false"
                                :touchable="false"
                                :gap="2.5"
                                :arrows="false">
                                <vueper-slide
                                    v-for="(slide, i) in accountImages" 
                                    :key="i"
                                    :image="slide.url + '/' +  slide.company_folder + '/' + slide.name"
                                    @click="$refs.vueperslides2.goToSlide(i)">
                                </vueper-slide>
                            </vueper-slides>
                        </div>
                    </div>
                </div>

                <div class="card card-xxl-stretch tab-pane fade show active" id="kt_accounting_6" role="tabpanel" v-if="this.activeTab == 'BundleProducts'">
                    <div class="card-header">
                        <div class="card-title">
                            <small>{{ $t('Create a bundle of products by combining stock accounts') }}.</small>
                        </div>
                        <div class="card-toolbar">
                            <button type="button" class="btn btn-info float-end me-3" data-bs-toggle="modal" ref="btnAddItem"
                                data-bs-target="#kt_modal_bundle_products" @click="clearModalForm()" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                {{ $t('Bundle Stock Accounts') }}
                            </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <ul class="nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-10 fs-6">
                            <li class="nav-item">
                                <a class="nav-link" :class="{'active': this.activeSubTab == 'BundlePlan'}" data-bs-toggle="tab" href="#kt_bundle_sub_1" target="_self"
                                @click="this.activeSubTab = 'BundlePlan', getBundleProducts()">
                                    <h4> {{ $t('Bundle Plan') }} </h4>
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" :class="{'active': this.activeSubTab == 'BundleTransaction'}" data-bs-toggle="tab" href="#kt_bundle_sub_2" target="_self"
                                @click="this.activeSubTab = 'BundleTransaction', getBundleTransactions()">
                                    <h4> {{ $t('Bundle Transactions') }} </h4>
                                </a>
                            </li>
                        </ul>
                        <div class="row">
                            <div id="kt_bundle_sub_1" class="tab-pane fade show active" v-if="this.activeSubTab == 'BundlePlan'" role="tabpanel">
                                <div class="col-lg-12">
                                    <form id="kt_bundle_products_form" @submit.prevent="" class="form fv-plugins-bootstrap5 fv-plugins-framework">  
                                        <div class="fv-row mb-6 row">   
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'bundle_product_id'">
                                                        {{ $t('Bundle Stock Account') }}
                                                    </label> 
                                                    <Select2 v-model="bundleProductsForm.bundle_product_id" :options="stockList" name="bundle_product_id" v-custom :settings="{width: '100%'}"/> 
                                                </div>
                                            </div> 
                                            <div class="col-lg-4">
                                                <div class="fv-row">
                                                    <label class="fs-6 fw-bold form-label mt-3 required" v-tag="'quantity'">
                                                        {{ $t('Quantity') }}
                                                    </label>
                                                    <CurrencyInput type="text" step="any" class="form-control text-end"  v-model="bundleProductsForm.quantity" @focus="$event.target.select()" />
                                                </div>
                                            </div>
                                        </div> 
                                    </form>
                                    <div class="separator my-10"></div>
                                    <div class="d-flex flex-stack flex-wrap my-5">
                                        <div class="d-flex justify-content-end" data-kt-docs-table-toolbar="base">
                                            <button type="button" class="btn btn-sm btn-light btn-active-success me-3" @click="addBundleProduct()" >
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                        viewBox="0 0 24 24" fill="none">
                                                        <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1"
                                                            transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                                        <rect x="4.36396" y="11.364" width="16" height="2" rx="1"
                                                            fill="currentColor">
                                                        </rect>
                                                    </svg>
                                                </span>
                                                {{ $t('Add') }}
                                            </button>
                                            <button type="button" class="btn btn-sm btn btn-light btn-active-danger me-3"  :disabled="this.bundleProductsItemList.length == 0"  @click="deleteBundleItem(true)">
                                                <span class="svg-icon svg-icon-2">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                                                    </svg>
                                                </span>
                                                {{ $t('Remove All (!)') }}
                                            </button>
                                        </div>
                                    </div>
                                    <div id="kt_datatable_example_1_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                                        <div class="table-responsive">
                                            <DxDataGrid id="gridContainer" :show-borders="true" 
                                                key-expr="id" 
                                                :data-source="bundleProductsItemList" 
                                                :allow-column-reordering="true"  
                                                :allow-column-resizing="true" 
                                                :column-auto-width="true" 
                                                :row-alternatin-enabled="true"
                                                :hover-state-enabled="true" 
                                                :show-row-lines="true"
                                                @row-updating="onRowUpdating"
                                                @row-removing="onRowDeleting"> 

                                                <DxEditing  :allow-deleting="true"  :allow-updating="true"  mode="row"/>
                                                <DxFilterRow :visible="true"/>
                                                <DxHeaderFilter :visible="true"/>
                                                <DxGroupPanel :visible="false"/>
                                                <DxPaging :page-size="1000"/>
                                                <DxPager :visible="false" :allowed-page-sizes="pageSizes" :show-page-size-selector="true" :show-info="true" :show-navigation-buttons="true"/> 
                                                <DxScrolling
                                                    mode="virtual"
                                                    row-rendering-mode="virtual"
                                                />                                                                
                                                <DxColumn data-field="product_code" :caption="$t('Product Code')" :allow-editing="false" cell-template="product-code-cell-template"></DxColumn>     
                                                <DxColumn data-field="account_name" :caption="$t('Bundle Product')" cell-template="account-name-cell-template" :allow-editing="false" :set-cell-value="setMainBranch">
                                                </DxColumn> 
                                                <DxColumn data-field="quantity" :editor-options="columnEditorOptions" :format="decimalFormat" data-type="number"
                                                    :sorting-method="this.$numericSort" :caption="$t('Quantity')" alignment="right" cell-template="quantity-cell-template" :allowEditing="true" :set-cell-value="setCommissionRateCellValue">
                                                    <DxFormat type="fixedPoint" :precision="2"/>
                                                </DxColumn>
                                                <DxColumn data-field="item_remain" :sorting-method="this.$numericSort" :caption="$t('Remains')" alignment="right" cell-template="item-remain-cell-template" :allow-editing="false"/>   
                                                <DxColumn data-field="created_by" :caption="$t('Created By')" cell-template="created-by-cell-template" :allow-editing="false"/> 
                                                <template #product-code-cell-template="{ data }">
                                                        <span class="text-gray-900"> 
                                                            {{ data.data.product_code }} 
                                                        </span> 
                                                </template>
                                                <template #account-name-cell-template="{ data }"> 
                                                    <span class="text-gray-900"> 
                                                        {{ data.data.account_name }} 
                                                    </span> 
                                                    <small v-if="data.data.is_bundle_product == 1" class="ms-2 badge badge-sm badge-success">Bundle</small>
                                                </template>
                                               
                                                <template #quantity-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.quantity }}
                                                    </span>
                                                </template>
                                                <template #item-remain-cell-template="{ data }">
                                                    <span class="mb-1" :class="{ 'text-danger': (parseFloat(data.data.item_remain) == 0) || (parseFloat(data.data.item_remain) < 0), 'text-gray-900': parseFloat(data.data.item_remain) > 0}">
                                                        {{ data.data.item_remain }}
                                                    </span>
                                                </template>
                                                <template #created-by-cell-template="{ data }"> 
                                                    <span>
                                                        {{ data.data.created_by }}
                                                    </span>
                                                </template>

                                                <DxSummary>
                                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="isSelected"/>
                                                </DxSummary>
                                            </DxDataGrid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="kt_bundle_sub_2" class="tab-pane fade show active" v-if="this.activeSubTab == 'BundleTransaction'" role="tabpanel">
                                <div class="col-lg-12">
                                    <div class="row px-0">
                                        <div class="col-lg-9">
                                            <form class="form fv-plugins-bootstrap5 fv-plugins-framework p-3 pb-0 pt-1 rounded-2">
                                                <div class="fv-row mb-7 row">
                                                    <div class="col-lg-3">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('Start Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="bundleFilterForm.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <label class="fs-6 fw-bold form-label mt-3">
                                                            <span>{{ $t('End Date') }}</span>
                                                        </label>
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="bundleFilterForm.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="d-flex justify-content-end mt-8" data-kt-customer-table-toolbar="base">
                                                <Popper hover placement="top">
                                                    <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                                        <i class="bi bi-eraser-fill fs-4"> </i>
                                                    </button>
                                                    <template #content>
                                                        <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                                                    </template>
                                                </Popper>
                                                <button type="button" class="btn btn-light-primary border border-primary" @click="getBundleTransactions()" data-kt-indicator="on">
                                                    <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                                                    <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                                                    <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                                                    </svg>
                                                    </span>
                                                    <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span> 
                                                    <span class="indicator-progress" v-if="this.isListProgressing">
                                                        {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                    </span> 
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="separator my-5 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                                    <div class="table-responsive min-h-300px">                                        
                                        <DxDataGrid id="gridContainer" :show-borders="true" 
                                            key-expr="id" 
                                            :data-source="getBundleTransactionList" 
                                            :allow-column-reordering="true"  
                                            :allow-column-resizing="true" 
                                            :column-auto-width="true" 
                                            :row-alternatin-enabled="true"
                                            :hover-state-enabled="true" 
                                            :show-row-lines="true" 
                                            :ref="'dataGrid'"
                                            @row-removing="onMasterBundleDelete"
                                            @row-prepared="onRowPrepared" 
                                            @exporting="this.$onExporting($event , 'Bundle-Transaction-List')"> 
                                            <DxMasterDetail
                                                :enabled="true"
                                                :autoExpandAll="true"
                                                template="masterDetailTemplate"
                                            />
                                            
                                            <DxFilterRow :visible="true"/>
                                            <DxHeaderFilter :visible="true"/>
                                            <DxGroupPanel :visible="false"/>      
                                            <DxExport :enabled="false" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                            <DxSearchPanel :visible="false"/>
                                            <DxScrolling
                                                mode="virtual"
                                                row-rendering-mode="virtual"
                                            />
                                            <DxEditing :allow-deleting="this.$isCredentialActive(16,'D') ? true : false"  mode="row"/>

                                            
                                            
                                            <DxColumn data-field="transaction_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Trns. Date')" />
                                            <DxColumn data-field="transaction_type_name" :caption="$t('Trns. Type')" /> 
                                            <DxColumn data-field="transaction_no" :caption="$t('Trns. No')" />
                                            <DxColumn data-field="invoice_type_name" :caption="$t('Source')" /> 
                                            <DxColumn data-field="invoice_no" :caption="$t('Source No')" /> 
                                            <DxColumn data-field="in_out_name" :caption="$t('In / Out')" />  
                                            <DxColumn data-field="currency" :caption="$t('Cur.')" />
                                            <DxColumn data-field="quantity1" :sorting-method="this.$numericSort" :caption="$t('Qty')"  alignment="right" />
                                            <DxColumn data-field="price" :sorting-method="this.$numericSort" :caption="$t('Price')" alignment="right" />
                                            <DxColumn data-field="total_price" :sorting-method="this.$numericSort" :caption="$t('Total Price')" alignment="right" />
                                            <DxColumn data-field="discount_amount" :sorting-method="this.$numericSort" :caption="$t('Discount')" alignment="right" />
                                            <DxColumn data-field="net_price" :sorting-method="this.$numericSort" :caption="$t('Net Price')" alignment="right" />
                                            <DxColumn data-field="tax_amount" :sorting-method="this.$numericSort" :caption="$t('Tax')" alignment="right" /> 
                                            <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Gross Amount')" alignment="right" />
                                            <DxColumn data-field="tax_rate" :caption="$t('Tax Rate')" alignment="right" /> 
                                            <!-- <DxColumn data-field="record_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Record Date')" /> -->
                                            <DxColumn data-field="description" :caption="$t('Description')" />

                                            <template #masterDetailTemplate="{ data }"> 
                                                <DxDataGrid :data-source="getBundleDetails(data.data)" :show-borders="true" :column-auto-width="true" >
                                                    <DxColumn data-field="transaction_date"  data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Trns. Date')" />
                                                    <DxColumn data-field="transaction_type_name" :caption="$t('Trns. Type')" /> 
                                                    <DxColumn data-field="transaction_no" :caption="$t('Trns. No')" />
                                                    <DxColumn data-field="stock_account_name" :caption="$t('Stock Acc.')" cell-template="stock-account-name-cell-template"/>
                                                    <DxColumn data-field="in_out_name" :caption="$t('In / Out')" />  
                                                    <DxColumn data-field="currency" :caption="$t('Cur.')" />
                                                    <DxColumn data-field="quantity1" :sorting-method="this.$numericSort" :caption="$t('Qty')"  alignment="right" />
                                                    <DxColumn data-field="price" :sorting-method="this.$numericSort" :caption="$t('Price')" alignment="right" />
                                                    <DxColumn data-field="total_price" :sorting-method="this.$numericSort" :caption="$t('Total Price')" alignment="right" />
                                                    <DxColumn data-field="discount_amount" :sorting-method="this.$numericSort" :caption="$t('Discount')" alignment="right" />
                                                    <DxColumn data-field="net_price" :sorting-method="this.$numericSort" :caption="$t('Net Price')" alignment="right" />
                                                    <DxColumn data-field="tax_amount" :sorting-method="this.$numericSort" :caption="$t('Tax')" alignment="right" /> 
                                                    <DxColumn data-field="gross_amount" :sorting-method="this.$numericSort" :caption="$t('Gross Amount')" alignment="right" />
                                                    <DxColumn data-field="tax_rate" :caption="$t('Tax Rate')" alignment="right" /> 
                                                    <!-- <DxColumn data-field="invoice_type_name" :caption="$t('Inv. Type.')" /> 
                                                    <DxColumn data-field="invoice_no" :caption="$t('Inv. No.')" />  -->
                                                    <!-- <DxColumn data-field="record_date" data-type="date" alignment="right" format="dd.MM.yyyy" :caption="$t('Record Date')" /> -->
                                                    <DxColumn data-field="description" :caption="$t('Description')" />

                                                    <template #stock-account-name-cell-template="{ data }"> 
                                                        <span class="text-gray-900"> 
                                                            {{ data.data.stock_account_name }} 
                                                        </span> 
                                                        <small v-if="data.data.is_bundle_product == 1" class="ms-2 badge badge-sm badge-success">Bundle</small>
                                                    </template>
                                                </DxDataGrid>
                                            </template>
                                           
                                        </DxDataGrid>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="kt_modal_add_price" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary py-3" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            <span class="fw-bolder" v-if="this.priceModalType == 'addPrice'"> {{ $t('Add Price') }} </span>
                            <span class="fw-bolder" v-if="this.priceModalType == 'editPrice'"> {{ $t('Edit Price') }} </span>                    
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>                  
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="Close">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body" :class="{'overlay overlay-block': this.isPriceSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="form">
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                    <span>{{ $t('Currency') }}</span>
                                                </label>
                                                <div class="col-lg-8 fv-row">
                                                    <Select2 v-model="priceForm.currency" :options="defaultCurrencyList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_price .modal-content'  }"
                                                        @select="onChangeCurrency()"/>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t('Sales Price / VAT Status / %VAT') }}</label>
                                                <div class="col-lg-8">
                                                    <div class="row">
                                                        <div class="col-lg-4 fv-row pe-1">
                                                            <CurrencyInput type="text" step="any" class="form-control text-end" v-model="priceForm.sales_price" @focus="$event.target.select()"/>
                                                        </div>
                                                        <div class="col-lg-4 fv-row ps-1">
                                                            <Select2 v-model="priceForm.sales_vat_status_id" :options="vatStatusList" @select="onChangeVatStatus('sales')" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_price .modal-content' }"/>
                                                        </div>
                                                        <div class="col-lg-4 fv-row ps-1 pe-1">
                                                            <Select2 v-model="priceForm.sales_tax_amount" :options="vatRateList" :disabled="this.priceForm.sales_vat_status_id == 1" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_price .modal-content' }"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t('Purchase Price / VAT Status / %VAT') }}</label>
                                                <div class="col-lg-8">
                                                    <div class="row">
                                                        <div class="col-lg-4 fv-row pe-1">
                                                            <CurrencyInput type="text" step="any" class="form-control text-end" v-model="priceForm.purchase_price" @focus="$event.target.select()"/>
                                                        </div>
                                                        <div class="col-lg-4 fv-row ps-1">
                                                            <Select2 v-model="priceForm.purchase_vat_status_id" :options="vatStatusList" @select="onChangeVatStatus('purchase')" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_price .modal-content' }"/>
                                                        </div>
                                                        <div class="col-lg-4 fv-row ps-1 pe-1">
                                                            <Select2 v-model="priceForm.purchase_tax_amount" :options="vatRateList" :disabled="this.priceForm.purchase_vat_status_id == 1" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_price .modal-content' }"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t('Price Type List') }}</label>
                                                <div class="col-lg-8">
                                                    <Select2 v-model="priceForm.price_type_id" :options="priceTypeList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_price .modal-content'  }"/>
                                                </div>
                                            </div>
                                            <div class="row mb-2">
                                                <label class="col-lg-4 col-form-label fw-bold fs-6">{{ $t('Default') }}</label>
                                                <div class="col-lg-8">
                                                    <input class="form-check-input mt-3" type="checkbox" value="" id="isDefault" v-model="priceForm.is_default" :disabled="this.isDefaultDisabled">
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4" id="kt_contacts_list">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" v-if="this.priceModalType == 'addPrice'"
                                            @click="this.savePriceData()" :disabled="this.isModalButtonDisabled">
                                            <span class="indicator-label">{{ $t('Add Price') }}</span> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.priceModalType == 'editPrice'"
                                            @click="this.updatePriceData()" :disabled="this.isModalButtonDisabled">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isPriceSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="kt_modal_add_campaign" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11">
                            {{ $t('Add Item') }}
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>  
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y mx-5" :class="{'overlay overlay-block': this.isPriceSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">                                
                                <div class="card card-flush" id="kt_contacts_list">
                                    <div class="card-body pt-0" id="kt_contacts_list_body">
                                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                                            novalidate="novalidate">
                                            <div class="card-body border-top p-1 pt-3">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Sales Channel') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <Select2 v-model="campaignForm.sales_channel_id" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_campaign .modal-content'  }"/>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Sales Role/Campaign') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <Select2 v-model="campaignForm.campaign_id" :options="campaignList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_campaign .modal-content'  }"/>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Payment') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <Select2 v-model="campaignForm.payment_id" :options="paymentList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_campaign .modal-content'  }"/> 
                                                    </div>
                                                </div>                                                
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Shipping') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <Select2 v-model="campaignForm.shipping_id" :options="shippingList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_campaign .modal-content'  }"/> 
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="card card-flush mt-5" id="kt_contacts_list">
                                    <div class="card-body pt-0" id="kt_contacts_list_body">
                                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                                            novalidate="novalidate">
                                            <div class="card-body border-top p-1 pt-2">                                           
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Currency') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="campaignForm.currency" :options="currencyList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_campaign .modal-content'  }"/>
                                                    </div>
                                                </div>                                                
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Price') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">                                                        
                                                        <CurrencyInput type="text" step="any" class="form-control text-end" v-model="campaignForm.stock_price" @focus="$event.target.select()"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4" id="kt_contacts_list">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" v-if="this.priceModalType == 'addPrice'">
                                            <span class="indicator-label">{{ $t('Add Sales Role / Campaign') }}</span> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.priceModalType == 'editPrice'">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isPriceSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="kt_modal_add_stock" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11">
                            {{ $t('Add Item') }}                     
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>  
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y mx-5" :class="{'overlay overlay-block': this.isPriceSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">                                
                                <div class="card card-flush" id="kt_contacts_list">
                                    <div class="card-body pt-0" id="kt_contacts_list_body">
                                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                                            novalidate="novalidate">
                                            <div class="card-body border-top p-1 pt-3">
                                                <!-- Bu grup Select an option seçili gelecek -->
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Sales Channel') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="stockCodeForm.sales_channel_id" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_stock .modal-content'  }"/>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Channel Stock Code') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <input type="text" class="form-control" name="company_name" v-model="stockCodeForm.channel_stock_code">
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4" id="kt_contacts_list">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" v-if="this.priceModalType == 'addPrice'">
                                            <span class="indicator-label">{{ $t('Add Stock Code') }}</span> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.priceModalType == 'editPrice'">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isPriceSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- TRACKING GROUP MODAL -->
        <div class="modal fade" id="kt_modal_add_tracking_group" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary py-3" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            {{ $t('Add Item') }}                       
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>  
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="closeModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body scroll-y mx-5" :class="{'overlay overlay-block': this.isPriceSaving == true}">
                        <div class="row">
                            <div class="col-lg-12">                                
                                <div class="card card-flush" id="kt_contacts_list">
                                    <div class="card-body pt-0" id="kt_contacts_list_body">
                                        <form id="kt_account_profile_details_form" class="form fv-plugins-bootstrap5 fv-plugins-framework"
                                            novalidate="novalidate">
                                            <div class="card-body border-top p-1 pt-3">
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                                                        <span>{{ $t('Tracking Group') }}</span>
                                                        <Popper hover placement="top" v-if="trackingForm.tracking_group_description !== null && trackingForm.tracking_group_description !== '' && trackingForm.tracking_group_description !== undefined">
                                                            <i class="bi bi-info-circle text-primary ms-3"> </i>
                                                            <template #content>
                                                                <span class="text-gray-800 fs-7"> {{trackingForm.tracking_group_description}} </span>
                                                            </template>
                                                        </Popper>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="trackingForm.tracking_group_id" :options="trackingGroupList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_tracking_group .modal-content'  }"
                                                            @select="onTrackingGroupSelection($event)"/>
                                                    </div>
                                                </div>
                                                <div class="row mb-2" v-if="this.trackingForm.groupSelection !== null && this.trackingForm.groupSelection.input_type == 1">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                                                        <span>{{ $t('Tracking Value') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <!-- <input type="text" class="form-control" name="company_name" v-model="trackingForm.item_value"> -->

                                                        <!-- TEXT -->
                                                        <input type="text" class="form-control " name="value" v-model="trackingForm.item_value" v-if="this.trackingForm.groupSelection.data_type == 1">
                                                        <!-- NUMBER -->
                                                        <CurrencyInput type="text" class="form-control" name="total_length" v-model="trackingForm.item_value" :precision="0" @focus="$event.target.select()" v-if="this.trackingForm.groupSelection.data_type == 2"/>
                                                        <!-- NUMBER DECIMAL -->
                                                        <CurrencyInput type="text" class="form-control" name="total_length" v-model="trackingForm.item_value" :precision="2" @focus="$event.target.select()" v-if="this.trackingForm.groupSelection.data_type == 3"/>
                                                        <!-- DATE -->
                                                        <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" 
                                                                    v-maska="'##.##.####'" 
                                                                    v-model="trackingForm.item_value" 
                                                                    inputClassName="form-control" 
                                                                    format="dd.MM.yyyy" autoApply 
                                                                    :clearable="false" 
                                                                    weekNumbers :monthChangeOnScroll="'inverse'" 
                                                                    v-if="this.trackingForm.groupSelection.data_type == 4"></Datepicker>
                                                    </div>
                                                </div>
                                                <div class="row mb-2" v-if="this.trackingForm.groupSelection !== null && this.trackingForm.groupSelection.input_type == 2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                                                        <span>{{ $t('Tracking Code List') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="trackingForm.list_value_id" :options="trackingValueList" 
                                                                 @select="onTrackingCodeSelection($event)"
                                                                 :settings="{ width: '100%', dropdownParent: '#kt_modal_add_tracking_group .modal-content'  }"/>
                                                    </div>
                                                </div>
                                                 <div class="row mb-2" v-if="this.trackingForm.groupSelection !== null && this.trackingForm.groupSelection.input_type == 2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6 required">
                                                        <span>{{ $t('Tracking Value') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <input type="text" class="form-control " name="value" v-model="trackingForm.item_value" :disabled="this.trackingForm.groupSelection.read_only == 1"/>
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Description') }}</span>
                                                        <!-- List veya input veya Date -->
                                                        <!-- Açıklama gruptan dolacak, isterse değiştirecek -->
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                    <input type="text" class="form-control" name="company_name" v-model="trackingForm.description">
                                                    </div>
                                                </div>
                                                <div class="row mb-2">
                                                    <label class="col-lg-4 col-form-label fw-bold fs-6">
                                                        <span>{{ $t('Sales Channel') }}</span>
                                                    </label>
                                                    <div class="col-lg-8 fv-row fv-plugins-icon-container">
                                                        <Select2 v-model="trackingForm.sales_channel_id" :options="salesChannelList" :settings="{ width: '100%', dropdownParent: '#kt_modal_add_tracking_group .modal-content'  }"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4" id="kt_contacts_list">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" v-if="this.trackingModalType == 'add'" @click="saveTrackingGroup()">
                                            <span class="indicator-label">{{ $t('Add') }}</span> 
                                        </button>
                                        <button type="button" class="btn btn-primary btn-lg" v-if="this.trackingModalType == 'edit'" @click="editTrackingGroup()">
                                            <span class="indicator-label">{{ $t('Update') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="overlay-layer bg-dark bg-opacity-5" v-if="this.isPriceSaving == true">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">{{ $t('Loading...') }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- Stock Account Image Upload -->
        <div class="modal fade" id="kt_modal_stock_image_uploads" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <AccountImageUpdate :account="form" :account-name="form.name" @refreshImageData="getAccountImageData(true)"  ref="AccountImageUpdate"/>
        </div>

        
        <div class="modal fade" id="kt_modal_bundle_products" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content"  >
                    <div class="modal-header bg-primary py-3" id="kt_modal_add_user_header">
                        <h2 class="modal-title fw-bolder col-md-11 text-white">
                            <span class="fw-bolder"> {{ $t('Bundle Products') }} </span>                
                        </h2>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary">
                            <i class="bi bi-gear fs-2 mt-1 cursor-pointer text-white text-hover-dark"></i>                  
                        </div>
                        <div class="btn btn-icon btn-sm btn-active-icon-primary"
                            data-bs-dismiss="modal" aria-label="Close" ref="onCloseBundleModal">
                            <span class="svg-icon svg-icon-1 text-white text-hover-dark">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                    viewBox="0 0 24 24" fill="none">
                                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                                        transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
                                    <rect x="7.41422" y="6" width="16" height="2" rx="1"
                                        transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <form class="form">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>{{ $t('Date') }}</span>
                                                    </label>
                                                    <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="bundleForm.record_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                                                </div>
                                                <div class="col-lg-6">
                                                    <label class="fs-6 fw-bold form-label mt-3">
                                                        <span>{{ $t('Quantity') }}</span>
                                                    </label>
                                                    <CurrencyInput type="text" step="any" class="form-control text-end"  v-model="bundleForm.quantity" @focus="$event.target.select()"/> 
                                                </div>
                                                <div class="col-lg-12 mt-5" v-if="costOfSalesMethodName !== null && costOfSalesMethodName !== undefined && costOfSalesMethodName !== ''">
                                                    <div class="notice d-flex bg-light-info rounded border-info border border-dashed p-3">
                                                        <i class="bi bi-info-circle fs-4 text-info me-1 mt-1"></i>     
                                                        <div class="d-flex flex-stack flex-grow-1 ">
                                                            <div class=" fw-semibold"> 
                                                                <div class="fs-6 text-gray-700 ">
                                                                    {{ $t('The cost of bundle products will be calculated by ') }} <b>{{ costOfSalesMethodName }}</b>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="card card-flush mt-4">
                                    <div class="d-grid gap-2"> 
                                        <button type="button" class="btn btn-success btn-lg" @click="createBundleTransaction()">
                                            <span class="indicator-label">{{ $t('Create') }}</span> 
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"; 
import Swal from "sweetalert2";
//import { ref } from 'vue';
import moment from 'moment';
import { VueperSlides, VueperSlide } from 'vueperslides'
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxScrolling, DxEditing, DxMasterDetail  } from 'devextreme-vue/data-grid';
import AccountImageUpdate from "../Modals/AccountImageUpdate.vue";


export default {
  name: "StockUpdate",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn, DxSummary, DxTotalItem, DxScrolling, DxEditing, DxMasterDetail, VueperSlides, VueperSlide, AccountImageUpdate
  },
  data() {
    return { 
        columnEditorOptions: { format: "#,##0.00", precision: 2 },
        decimalFormat: "#,##0.00",
        lists: [],
        userData: this.$getUser(),
        stockGroupList: [],
        productGroupList: [],
        productTypeList: [],
        productDetailList: [],
        unitTypeList: [],
        unitType2List: [],
        stockCurrencyList: [],
        accountSubTypeList: [{ id: 0, text: 'Stock Account'}, { id: 1, text: 'Service Account'}, { id: 2, text: 'Property Account'}],
        salesChannelList: [{ id: '-1', text: 'Select an option'}],
        campaignList: [{ id: '-1', text: 'Select an option'}],
        paymentList: [{ id: '-1', text: 'Select an option'}],
        shippingList: [{ id: '-1', text: 'Select an option'}],
        currencyList: [{ id: '-1', text: 'Select an option'}],
        vatStatusList: [
            { id: 1, text: 'Select an option'},
            { id: 2, text: 'VAT (+)'},
            { id: 3, text: 'VAT (in)'}
        ],
        vatRateList: [{ id: '0.00', text: 'Select an option'}],
        defaultCurrencyList: [],
        priceTypeList: [{ id: '-1', text: 'Select an option'}],
        priceModalType: 'addPrice',
        isProgressing: false,
        bundleProductsItemList:[],
        stockList:[{ id: '-1', text: 'Select an option'}],
        form: {
            id: null,
            name: null,
            company_id: null,
            account_type: null,
            account_id: null,
            qr_code: null,
            notes: null,
            stock_group_id: '-1',
            product_group_id: '-1',
            product_type_id: '-1',
            product_detail_id: '-1',
            stock_unit_id: '-1',
            stock_unit_2_id: '-1',
            accounting_plan: null,
            product_code: null,
            account_sub_type: 0,
            item_remain: 0,
            item_used: 0,
            item_amount: 0,
            critical_stock_quantity: 0,
            stock_image_url: null,
            is_bundle_product: false
        },
        priceForm: {
            currency: '-1',
            sales_price: 0.00,
            sales_tax_amount: '0.00',
            purchase_price: 0.00,
            purchase_tax_amount: '0.00',
            price_type_id: '-1',
            is_default: false,
            sales_vat_status_id: 2,
            purchase_vat_status_id: 2,
        },
        campaignForm: {
            currency: '-1',
            payment_id: '-1',
            shipping_id: '-1',
            campaign_id: '-1',
            sales_channel_id: '-1',
            stock_price: 0.00
        },
        stockCodeForm: {
            sales_channel_id: '-1',
            channel_stock_code: null
        },
        trackingForm: {
            id: null,
            tracking_group_id: 0,
            item_value: null,
            list_value_id: null,
            description: null,
            sales_channel_id: 0,
            groupSelection: { id: null, text: null, input_type: 0, data_type: 0},
            tracking_group_description: null
        }, 
        stockTrackingListItems: [],
        trackingGroupList: [],
        trackingValueList: [],
        localCurrency: null,
        isDefaultDisabled: false,
        isViewAccountId: false,
        isPriceSaving: false,
        isModalButtonDisabled: false,
        trackingModalType: 'add',
        activeTab: 'Account',
        menuSelection: '/StockHelppage',
        iconView : false,
        pageSize: 10,
        pageSizeStock: 10,
        pageSizeLists: 10,
        folderId: null,
        accountImages: [],
        activeSubTab: null,
        bundleProductsForm:{
            id:null,
            bundle_product_id: '-1',
            bundle_parent_id: null,
            quantity: 1.00
        },
        bundleForm: {
            record_date: moment(),
            quantity: 1.00
        },
        bundleFilterForm: {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment()
        },
        bundleTransactionList:[],
        costOfSalesMethodName: null
    };
  },
  computed:{
    isItemButtonDisabled() {
       return this.stockCurrencyList.find(item =>item.isSelected == true );
    },
    isStockTrackingItemButtonDisabled() {
       return this.stockTrackingListItems.find(item =>item.isSelected == true );
    },
    showPageSizeSelector() {
        return this.stockCurrencyList.length > this.pageSize;
    },
    isBundleButtonDisabled() {
        return this.bundleProductsItemList.find(item => item.isSelected == true );
    },
    pageSizes() {
        let arr = [];
        let x = 1;
        let loop = (this.stockCurrencyList.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    showPageSizeSelectorStock() {
        return this.stockTrackingListItems.length > this.pageSizeStock;
    },
    pageSizesStock() {
        let arr = [];
        let x = 1;
        let loop = (this.stockTrackingListItems.length / this.pageSizeStock);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSizeStock * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    showPageSizeSelectorLists() {
        return this.lists.length > this.pageSizeLists;
    },
    pageSizesLists() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSizeLists);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSizeLists * x);
            x++;
        }
        if(loop > 5) arr.push(100);
        if(loop > 1) arr.push('all');
        return arr;
    },
    getBundleTransactionList() {
      return this.bundleTransactionList.filter(p => { 
        return p.row_type == 0;
      });
    },
    getBundleDetails() {
      return(value) => {
        return this.bundleTransactionList.filter(p => { 
          return p.row_type == 1 && p.transaction_type_id == value.transaction_type_id;
        });
      }
    }
  },
  methods: {
    getData(isRefreshData) {
        if(!isRefreshData) this.$Progress.start();
        const params = {
            id: this.$getSessionStorage('record_id'),
            session_id: this.$getUser().session_id
        };
        axios.post('api/CompanyAccounts/GetAccountById', params, {'Content-Type': 'application/json'})
        .then((response) => {     
            this.form.id = response.data.id;
            this.form.name = this.$unlock(response.data.name);
            this.form.account_id = this.$unlock(response.data.account_id);
            this.form.qr_code = this.$unlock(response.data.qr_code);
            this.form.stock_image_url = this.$unlock(response.data.stock_image_url);
            this.form.product_code = response.data.product_code;
            this.form.account_type = response.data.account_type;
            this.form.notes = response.data.notes;
            this.form.item_used = response.data.item_used;
            this.form.item_remain = response.data.item_remain;
            this.form.item_amount = response.data.item_amount;
            this.form.account_sub_type = response.data.account_sub_type;
            this.form.stock_group_id = (response.data.stock_stock_group_id == null || response.data.stock_stock_group_id == '') ? '-1': response.data.stock_stock_group_id;
            this.form.product_group_id = (response.data.stock_product_group_id == null || response.data.stock_product_group_id == '') ? '-1': response.data.stock_product_group_id;
            this.form.product_type_id = (response.data.stock_product_type_id == null || response.data.stock_product_type_id == '') ? '-1': response.data.stock_product_type_id;
            this.form.product_detail_id = (response.data.stock_product_detail_id == null || response.data.stock_product_detail_id == '') ? '-1': response.data.stock_product_detail_id;
            this.form.stock_unit_id = (response.data.stock_unit_id == null || response.data.stock_unit_id == '') ? '-1': response.data.stock_unit_id;
            this.form.stock_unit_2_id = (response.data.stock_unit_2_id == null || response.data.stock_unit_2_id == '') ? '-1': response.data.stock_unit_2_id; 
            this.form.critical_stock_quantity = response.data.stock_critical_stock_quantity;
            this.form.is_bundle_product = response.data.is_bundle_product == 1 ? true : false;
            if(!isRefreshData) this.getLists();

            
            if(isRefreshData) {
                if (this.activeSubTab == "BundlePlan") this.getBundleProducts();
                if (this.activeSubTab == "BundleTransaction") this.getBundleTransactions();
            }
        })
        .catch(function (error) {
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        });
    },
    getLists() {
        this.salesChannelList = [];
        const parameters = { 
            session_id: this.$getUser().session_id,
            list_name: ["currency", "account_setting", "product_group", "sales_channel"],
            account_type: 7 // yalnızca account settings listesi çekilecek ise gerekli
        }; 
        axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}).then((response) => {
            this.defaultCurrencyList = response.data.currency;
            this.localCurrency = this.$getUser().currency_code;
            this.productGroupList = response.data.product_group;
            this.productGroupList.unshift({ id: '-1', text: 'Select an option'}); 
            if (response.data.account_setting.is_default == false) {
                this.isViewAccountId = true;
            }
            this.salesChannelList = response.data.sales_channel;
            this.salesChannelList.unshift({ id: 0, text: 'Select an option'});  
            this.trackingForm.sales_channel_id = (this.trackingForm.sales_channel_id == '' || this.trackingForm.sales_channel_id == null) ? 0 : this.trackingForm.sales_channel_id;

            const params = {
                session_id: this.$getUser().session_id,
                list_types: [
                    {
                        list_type: 6,
                        list_name: "stock_group"
                    },
                    {
                        list_type: 9,
                        list_name: "unit_type"
                    },
                    {
                        list_type: 10,
                        list_name: "unit_2_type"
                    }
                ],
            };
            axios.post("/api/Helper/GetMultiLists", params, {'Content-Type': 'application/json'}).then((response) => {
                this.stockGroupList = response.data.stock_group;
                this.unitTypeList = response.data.unit_type; 
                this.unitType2List = response.data.unit_2_type; 
                this.stockGroupList.unshift({ id: '-1', text: 'Select an option'}); 
                this.unitTypeList.unshift({ id: '-1', text: 'Select an option'});
                this.unitType2List.unshift({ id: '-1', text: 'Select an option'});
                
                this.onChangeProductGroup(false);
                this.onChangeProductType(false);
                this.getStockCurrencies();
                this.getStockTrackingGroups();
                this.$Progress.finish();
            })
            .catch(function (error) {
                console.log(error);
                this.$Progress.finish();
            });
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    onChangeProductGroup(isSetDefault) {
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: this.form.product_group_id
        };
        axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.productTypeList = response.data;
                this.productTypeList.unshift({ id: '-1', text: 'Select an option'});
                if(isSetDefault) {
                    this.form.product_type_id = '-1';
                    this.productTypeList.forEach((value) => {
                        if(value.is_default) {
                            this.form.product_type_id = value.id;
                        }
                    });
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onChangeProductType(isSetDefault) {
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: this.form.product_type_id
        };
        axios.post("/api/Helper/GetProductDetailParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                this.productDetailList = response.data;
                this.productDetailList.unshift({ id: '-1', text: 'Select an option'}); 
                if(isSetDefault) {
                    this.form.product_detail_id = '-1';
                    this.productDetailList.forEach((value) => {
                        if(value.is_default) {
                            this.form.product_detail_id = value.id;
                        }
                    });
                }
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    saveData(){
      this.$checkSelect2Validation('poliform');
      if(!document.forms['poliform'].reportValidity()){
        return;
      }
      try{
        var data = sessionStorage.getItem('frm_val'); 
        var priceTableValidation = JSON.parse(data).find((item) => item.field == "price_table");
        var trackingGroupTableValidation = JSON.parse(data).find((item) => item.field == "tracking_group_table");
        if(priceTableValidation !== null && priceTableValidation !== undefined && priceTableValidation !== ''){
            if(priceTableValidation.is_required){
                if(this.stockCurrencyList.length == 0){
                    this.$swal("Failed", "Price Table entry is required..!", 'error');
                    return;
                }
            } 
        }
        if(trackingGroupTableValidation !== null && trackingGroupTableValidation !== undefined && trackingGroupTableValidation !== ''){
            if(trackingGroupTableValidation.is_required){
                if(this.stockTrackingListItems.length == 0){
                    this.$swal("Failed", "Tracking Group entry is required..!", 'error');
                    return;
                }
            } 
        }
      }
      catch(Err){
        console.error(Err);
      }
      this.isProgressing = true;
      const params = this.form;    
      const that = this;
      params.session_id = this.$getUser().session_id;
      params.tmp_session_id = this.$getSessionStorage('tmp_session_id');
      if(params.stock_unit_2_id == '-1' ){ params.stock_unit_2_id = null; }
      if(params.product_group_id == '-1'){ params.product_group_id = null;}
      if(params.product_type_id == '-1'){ params.product_type_id = null; } 
      if(params.product_detail_id == '-1'){ params.product_detail_id = null; } 
      if(params.stock_group_id == '-1'){ params.stock_group_id = null }
      if(params.stock_unit_id == '-1'){ params.stock_unit_id = null }
      params.is_bundle_product = params.is_bundle_product ? 1 : 0;
      axios.post('api/CompanyAccounts/UpdateAccount', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(response.data != null && response.data.length == 36){  
                this.$swal("Success", "Data saved succesfully..!", 'success');  
                this.getData();
            }
        })
        .catch(function () { 
            new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be saved..Please refresh the page and try again..!' });
        })
        .finally(function () { 
            setTimeout(() => {
                that.isProgressing = false;
            }, 500);
        }); 
    },
    deleteItem() { 
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
          }).then((result) => { 
            if (result.isConfirmed) {
            const params = {
                session_id: this.$getUser().session_id,
                id: this.form.id
            }; 
            axios.post('api/CompanyAccounts/DeleteAccount', params, {'Content-Type': 'application/json'})
                .then((response) => { 
                    if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){ 
                        this.$router.push({ name: 'StockList' });
                    }
                })
                .catch(function () { 
                    new Swal( { icon: 'error', title: 'Error...', text: 'Data could not be deleted..Please refresh the page and try again..!' });   
                });  
            }
          })  
    },
    openPriceModal(value) {
        try {
            this.$refs.btnAddItem.blur();
            this.$refs.btnEditItem.blur();
        } catch(Err){ console.log(Err);}

        this.priceModalType = value;
        this.isPriceSaving = false;
        this.isModalButtonDisabled = false;
        this.priceForm.currency = this.localCurrency;
        this.priceForm.sales_price = 0.00;
        this.priceForm.sales_tax_amount = '0.00';
        this.priceForm.purchase_price = 0.00;
        this.priceForm.purchase_tax_amount = '0.00';
        this.priceForm.is_default = false;
        this.priceForm.price_type_id = '-1';
        this.priceForm.sales_vat_status_id = 2;
        this.priceForm.purchase_vat_status_id = 2;
        this.isDefaultDisabled = false;

        if (this.stockCurrencyList.length == 0) {
            this.isDefaultDisabled = true;
            this.priceForm.currency = this.localCurrency;
            this.priceForm.is_default = true;
        }

        if (this.priceModalType == 'editPrice') {
            this.stockCurrencyList.forEach((item) => {
                if (item.isSelected) {
                    this.priceForm.id = item.id;
                    this.priceForm.stock_account_id = item.stock_account_id;
                    this.priceForm.currency = item.currency;
                    this.priceForm.sales_price = item.sales_price.replace(/,/g, '');
                    this.priceForm.sales_tax_amount = item.sales_tax_amount.replace(/,/g, '');
                    this.priceForm.purchase_price = item.purchase_price.replace(/,/g, '');
                    this.priceForm.purchase_tax_amount = item.purchase_tax_amount.replace(/,/g, '');
                    this.priceForm.is_default = item.is_default;
                    this.priceForm.price_type_id = item.price_type_id ? item.price_type_id : '-1';
                    this.priceForm.is_default = item.is_default;
                    this.priceForm.sales_vat_status_id = item.sales_vat_status_id;
                    this.priceForm.purchase_vat_status_id = item.purchase_vat_status_id;
                }
            }); 
        }
        this.getPriceModalLists();
    },
    onChangeCurrency() {
        const isCurrencyExist = this.stockCurrencyList.find(item => item.currency == this.priceForm.currency);
        if (!isCurrencyExist) {
            this.isDefaultDisabled = true;
            this.priceForm.is_default = true;
        }
    },
    getPriceModalLists() {
        const params = {
            session_id: this.$getUser().session_id,
            list_types: [
                {
                    list_type: 13,
                    list_name: "price_type"
                },
                {
                    list_type: 15,
                    list_name: "vat_rate"
                }
            ],
        };
        axios.post('/api/Helper/GetMultiLists', params, {'Content-Type': 'application/json'})
        .then((response) => { 
            this.priceTypeList = response.data.price_type; 
            this.vatRateList = response.data.vat_rate;
            this.vatRateList.forEach((value) => {
                value.id = value.val;
            });
            this.priceTypeList.unshift({ id: '-1', text: 'Select an option'});
            this.vatRateList.unshift({ id: '0.00', text: 'Select an option'});
            if (this.priceModalType == 'addPrice') {
                this.priceTypeList.forEach((value) => {
                    if (value.is_default) {
                        this.priceForm.price_type_id = value.id;
                    }
                });
                this.vatRateList.forEach((value) => {
                    if(value.is_default) {
                        this.priceForm.sales_tax_amount = value.val;
                        this.priceForm.purchase_tax_amount = value.val;
                    }
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onSelectItem(item) {
        this.stockCurrencyList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });    
    },
    onSelectStockTrackingItem(item) {
        this.stockTrackingListItems.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });    
    },
    getStockCurrencies() {
        const params = {
            stock_account_id: this.form.id,
            session_id: this.$getUser().session_id
        };
        axios.post("/api/CompanyAccounts/GetStockCurrencies", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.stockCurrencyList = response.data;
            // this.getCurrencies();
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/StockHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    savePriceData() {
      const params = this.priceForm;
      this.isModalButtonDisabled = true;
      this.isPriceSaving = true;
      const that = this;
      params.session_id = this.$getUser().session_id;
      params.stock_account_id = this.form.id;
      if (params.price_type_id == '-1' || params.price_type_id == '' || params.price_type_id == undefined) {
        params.price_type_id = null;
      }
      axios.post('api/CompanyAccounts/AddStockCurrency', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(!response.data.error){
                this.$refs.Close.click();
                this.getStockCurrencies();
            }
            this.isPriceSaving = false;
        })
        .catch(function (error) {
            this.isPriceSaving = false;
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");
        })
        .finally(function () { 
            setTimeout(() => {
                that.isPriceSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });  
    },
    updatePriceData() {
        this.isModalButtonDisabled = true;
        this.isPriceSaving = true;
        const that = this;
        const params = this.priceForm;
        params.session_id = this.$getUser().session_id;
        if (params.price_type_id == '-1' || params.price_type_id == '' || params.price_type_id == undefined) {
            params.price_type_id = null;
        }
        axios.post('api/CompanyAccounts/UpdateStockCurrency', params, {'Content-Type': 'application/json'})
        .then((response) => {
            if(!response.data.error){
                this.$refs.Close.click();
                this.getStockCurrencies();
            }
            this.isPriceSaving = false;
        })
        .catch(function (error) {
            this.isPriceSaving = false;
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   
        })
        .finally(function () { 
            setTimeout(() => {
                that.isPriceSaving = false;
                that.isModalButtonDisabled = false;
            }, 500);
        });  
    },
    deletePrice(){
        this.$swal.fire({
            title: 'Are you sure you want to delete this data?',
            confirmButtonColor: '#f1416c',
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            icon: 'warning'
          }).then((result) => { 
            if (result.isConfirmed) {
                this.stockCurrencyList.forEach((item) => {
                    if(item.isSelected) {
                        const params = {
                            session_id: this.$getUser().session_id,
                            id: item.id
                        }; 
                        axios.post('api/CompanyAccounts/DeleteStockCurrency', params, {'Content-Type': 'application/json'})
                        .then(() => {
                            this.getStockCurrencies();
                        })
                        .catch(function (error) {
                            console.log(error);
                            this.$swal("Failed", "Data could not be deleted..Please refresh the page and try again..!");   
                        });  
                    }
                });
            }
          })   
    },
    deleteAllPrices() {
        this.$swal.fire({
            title: "Are you sure you want to delete all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE ALL (!)",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                const params = { 
                    session_id: this.$getUser().session_id,
                    stock_account_id: this.form.id
                }; 
                axios.post('api/CompanyAccounts/DeleteAllStockCurrencies', params, {'Content-Type': 'application/json'})
                .then(() => {
                    this.getStockCurrencies();
                })
                .catch(function (error) {
                    console.log(error);
                    this.$swal("Failed", "Data could not be deleted..Please refresh the page and try again..!");   
                });
            }
        });
    },
    getCurrencies(){
        const params = {
            session_id: this.$getUser().session_id
        };    
        axios.post('/api/Currency/GetList', params, {'Content-Type': 'application/json'})
             .then((response) => { 
                this.defaultCurrencyList = response.data;
                this.$Progress.finish();
             })
             .catch(function (error) {
                console.log(error); 
                this.$Progress.fail();
             });
    },
    onClickAddTrackingGroup(modalType){ 
        this.trackingModalType = modalType;
        this.trackingGroupList = [];
        this.trackingForm = {
            id: null,
            tracking_group_id: 0,
            item_value: null,
            list_value_id: null,
            description: null,
            sales_channel_id: 0,
            groupSelection: { id: null, text: null, input_type: 0, data_type: 0, read_only: 0 },
            tracking_group_description: null
        };
        const parameters = { 
            session_id: this.$getUser().session_id
        }; 
        axios.post('/api/StockTrackingGroups/GetStockTrackingGroups', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
                if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                    this.trackingGroupList = response.data;
                    this.trackingGroupList.unshift(
                        { id: 0, text: 'Select an option'}
                    );

                    if(modalType == 'edit'){
                        this.stockTrackingListItems.forEach((item) => {
                            if (item.isSelected) {
                                 this.trackingForm = {
                                    id: item.id,
                                    tracking_group_id: item.tracking_group_id,
                                    item_value: item.item_value,
                                    list_value_id: item.list_value_id,
                                    description: item.description,
                                    sales_channel_id: item.sales_channel_id,
                                    groupSelection: { 
                                        id: item.tracking_group_id, 
                                        text: item.name, 
                                        input_type: 0, 
                                        data_type: 0,
                                        read_only: 0
                                    },
                                    tracking_group_description: null
                                };

                                this.trackingGroupList.forEach((groupItem) => { 
                                    if(item.tracking_group_id == groupItem.id){
                                        this.trackingForm.groupSelection.data_type = groupItem.data_type;
                                        this.trackingForm.tracking_group_description = groupItem.description;
                                        this.trackingForm.groupSelection.read_only = groupItem.read_only;
                                    }
                                });

                                this.trackingForm.groupSelection.input_type = (item.list_value_id == null || item.list_value_id == '') ? 1 : 2;  
                                this.trackingForm.sales_channel_id = this.trackingForm.sales_channel_id == '' || this.trackingForm.sales_channel_id == null ? 0 : this.trackingForm.sales_channel_id;
  
                                const parameters = { 
                                    session_id: this.$getUser().session_id,
                                    stock_tracking_group_id: item.tracking_group_id
                                }; 
                                axios.post('/api/StockTrackingGroups/GetStockTrackingGroupItems', parameters, {'Content-Type': 'application/json'})
                                .then((response) => { 
                                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                                            this.trackingValueList = response.data;
                                            this.trackingValueList.unshift({ id: 0, text: 'Select an option'});

                                          
                                            // Track Value değeri elle düzeltilmiş olabilir. Bu sebeple orijinal value veya düzenlenmiş değer setlenir.
                                            if(this.trackingForm.item_value == null || this.trackingForm.item_value == ''){
                                                 this.trackingValueList.forEach((item) => { 
                                                    if(this.trackingForm.list_value_id == item.id){
                                                        this.trackingForm.item_value = item.value;
                                                    }
                                                }); 
                                            }
                                            else{ 
                                                this.trackingForm.item_value = item.item_value;
                                            }
                                        }
                                }) 
                                .catch(function (error) {
                                    console.log(error);
                                    this.Progress.finish();
                                });     
                            }
                        }); 
                    } 
                }
        }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish();
        }); 
    },
    onTrackingGroupSelection(e){
        this.trackingValueList = [];
        this.trackingForm.item_value = null;
        this.trackingForm.list_value_id = 0;
        // this.trackingForm.sales_channel_id = e.sales_channel == null || e.sales_channel == '' ? 0 : e.sales_channel;
        this.trackingForm.tracking_group_description = e.description;

        this.trackingForm['groupSelection'] = e; 
        this.trackingForm.groupSelection['data_type'] = e.data_type;
        // if(e.description !== null && e.description !== '' && e.description !== undefined){
        //     this.trackingForm.description = e.description;
        // }
        if(e.input_type == 2){
            const parameters = { 
                session_id: this.$getUser().session_id,
                stock_tracking_group_id: e.id
            }; 
            axios.post('/api/StockTrackingGroups/GetStockTrackingGroupItems', parameters, {'Content-Type': 'application/json'})
            .then((response) => { 
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.trackingValueList = response.data;
                        this.trackingValueList.unshift({ id: 0, text: 'Select an option'});
                    }
            }) 
            .catch(function (error) {
                console.log(error);
                this.Progress.finish();
            });       
        }    
    },
    saveTrackingGroup(){
        if(this.trackingForm.tracking_group_id == null || this.trackingForm.tracking_group_id == '' || this.trackingForm.tracking_group_id == 0){ 
            this.$swal("Warning", "Input field can not be empty..!", 'info');  
            return; 
        }
        if(this.trackingForm.groupSelection.input_type == 1 && this.trackingForm.groupSelection.data_type > 0){
            if(this.trackingForm.item_value == null || this.trackingForm.item_value == ''){
                this.$swal("Warning", "Input field can not be empty..!", 'info');  
                return;
            }
        } 
        if(this.trackingForm.groupSelection.input_type == 2 && this.trackingForm.groupSelection.data_type > 0 ){
            if(this.trackingForm.item_value == null || this.trackingForm.item_value == '' || this.trackingForm.list_value_id == null || this.trackingForm.list_value_id == 0){
                this.$swal("Warning", "Input field can not be empty..!", 'info');  
                return;
            }
        }
        this.stockTrackingListItems = [];
        const parameters = { 
          session_id: this.$getUser().session_id,
          tmp_session_id: this.$getSessionStorage('tmp_session_id'),
          item_value: this.trackingForm.item_value,
          list_value_id: this.trackingForm.list_value_id,
          description: this.trackingForm.description, 
          sales_channel_id: this.trackingForm.sales_channel_id,
          tracking_group_id: this.trackingForm.tracking_group_id
        }; 
        parameters.item_value = parameters.item_value == null || parameters.item_value == '' ? null : String(parameters.item_value);
        parameters.list_value_id = parameters.list_value_id == '' || parameters.list_value_id == 0 ? null : parameters.list_value_id;
        parameters.item_value = this.trackingForm.groupSelection.data_type == 4 ? moment(parameters.item_value).format('YYYY-MM-DD') : parameters.item_value;
        parameters.sales_channel_id = parameters.sales_channel_id == 0 || parameters.sales_channel_id == '' ? null : parameters.sales_channel_id;
        axios.post('/api/StockTrackingGroups/CreateStockTrackingGroupsItemsTemps', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                this.stockTrackingListItems = response.data;
                if(this.stockTrackingListItems.length > 0){
                    this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
                }
                this.$refs.closeModal.click();
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    editTrackingGroup(){
        if(this.trackingForm.tracking_group_id == null || this.trackingForm.tracking_group_id == '' || this.trackingForm.tracking_group_id == 0){ 
            this.$swal("Warning", "Input field can not be empty..!", 'info');  
            return; 
        }
        if(this.trackingForm.groupSelection.input_type == 1 && this.trackingForm.groupSelection.data_type > 0){
            if(this.trackingForm.item_value == null || this.trackingForm.item_value == ''){
                this.$swal("Warning", "Input field can not be empty..!", 'info');  
                return;
            }
        } 
        if(this.trackingForm.groupSelection.input_type == 2 && this.trackingForm.groupSelection.data_type > 0 ){
            if(this.trackingForm.item_value == null || this.trackingForm.item_value == '' || this.trackingForm.list_value_id == null || this.trackingForm.list_value_id == 0){
                this.$swal("Warning", "Input field can not be empty..!", 'info');  
                return;
            }
        }
        this.stockTrackingListItems = [];
        const parameters = { 
          id: this.trackingForm.id,
          session_id: this.$getUser().session_id,
          tmp_session_id: this.$getSessionStorage('tmp_session_id'),
          item_value: this.trackingForm.item_value,
          list_value_id: this.trackingForm.list_value_id,
          description: this.trackingForm.description, 
          sales_channel_id: this.trackingForm.sales_channel_id,
          tracking_group_id: this.trackingForm.tracking_group_id
        }; 
        parameters.item_value = parameters.item_value == null || parameters.item_value == '' ? null : String(parameters.item_value);
        parameters.list_value_id = parameters.list_value_id == '' || parameters.list_value_id == 0 ? null : parameters.list_value_id;
        parameters.item_value = this.trackingForm.groupSelection.data_type == 4 ? moment(parameters.item_value).format('YYYY-MM-DD') : parameters.item_value;
        parameters.sales_channel_id = parameters.sales_channel_id == 0 || parameters.sales_channel_id == '' ? null : parameters.sales_channel_id;

        axios.post('/api/StockTrackingGroups/UpdateStockTrackingGroupsItemsTemps', parameters, {'Content-Type': 'application/json'})
          .then((response) => {
                this.stockTrackingListItems = response.data; 
                this.$refs.closeModal.click();
           }) 
          .catch(function (error) {
              console.log(error);
              this.Progress.finish();
          });          
    },
    removeTrackingGroup(){
        this.stockTrackingListItems.forEach((item) => {
            if (item.isSelected) {
                this.trackingForm.id = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.stockTrackingListItems = [];
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id'),
                    id: this.trackingForm.id
                }; 
                axios.post('/api/StockTrackingGroups/DeleteStockTrackingGroupsItemsTemps', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.stockTrackingListItems = response.data;
                            this.$refs.closeModal.click();
                        } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    removeAllTrackingGroup(){ 
        this.$swal.fire({
            title: "Are you sure you want to remove all items?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) { 
                this.stockTrackingListItems = [];
                const parameters = {
                    session_id: this.$getUser().session_id,
                    tmp_session_id: this.$getSessionStorage('tmp_session_id')
                }; 
                axios.post('/api/StockTrackingGroups/DeleteStockTrackingGroupsItemsTempsAll', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                        if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                            this.stockTrackingListItems = [];
                            this.$refs.closeModal.click();
                        } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    getStockTrackingGroups(){
        const parameters = { 
            session_id: this.$getUser().session_id,
            account_id: this.$getSessionStorage('record_id')
        }; 
        axios.post('/api/StockTrackingGroups/GetStockTrackingGroupsItemsTempsById', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.stockTrackingListItems = response.data;
            if(this.stockTrackingListItems.length > 0){
                this.$setSessionStorage('tmp_session_id', response.data[0].tmp_session_id);
            }
        }) 
        .catch(function (error) {
            console.log(error);
            this.Progress.finish(); 
        });          
    },
    getAccountImageData(isRefresh){
        const params = {
            session_id: this.$getUser().session_id,
            account_id : this.form.id
        }
        axios.post('/api/MediaLibrary/GetAccountImages',params,{'Content-Type' : 'application/json'})
        .then((response) => {
            this.accountImages = response.data;

            if(isRefresh && this.accountImages.length > 0) {
                var imageData = this.accountImages.find(p => p.is_default);
                if(imageData !== null && imageData !== undefined && imageData !== '') {
                    this.form.stock_image_url = imageData.url + '/' + imageData.company_folder + '/' + imageData.name;
                }
            }
        }).catch((error)=>{
            console.log(error);
            this.$swal("Failed", "Data could not be saved..Please refresh the page and try again..!");   

        });
    },
    onTrackingCodeSelection(e){
        this.trackingValueList.forEach((item) => { 
            if(e.id == item.id){
                this.trackingForm.item_value = item.value;
            }
        });
    },
    activateDocumentsSection(){
        this.activeTab = 'Documents'; 
    },
    activateBundleProductsSection(){
        this.activeTab = 'BundleProducts';
        this.activeSubTab = 'BundlePlan';
        this.getBundleProductLists();
    },
    onChangeVatStatus(type) {
        if (type == 'sales') {
            if(this.priceForm.sales_vat_status_id == 1) {this.priceForm.sales_tax_amount = '0.00';}
        }
        if (type == 'purchase') {
            if(this.priceForm.purchase_vat_status_id == 1) {this.priceForm.purchase_tax_amount = '0.00';}
        }
    },
    onStockAccountImageUploads(){
        this.$refs.AccountImageUpdate.getFolderId();
    },
    getBundleProductLists(){       
        this.stockList = [];
        const parameters = {
            session_id: this.$getUser().session_id,
            account_id : this.form.id,
            list_type: 13, // PriceType: 13
        };        
        axios.post('/api/GetCompanyAccounts/GetStockAccountBundle', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            this.stockList = response.data.stock_data;
            this.stockList.forEach(element => {
                element.name = this.$unlock(element.name);
                element.account_id = this.$unlock(element.account_id);
                element.text = this.$unlock(element.text);
            });
            this.stockList.unshift({id:'-1',text:'Select an option'});
            this.getBundleProducts();
        }).catch(errors => { 
            console.error(errors); 
        }); 
    },
    getBundleProducts(){
        const params = {
            session_id : this.$getUser().session_id,
            account_id : this.form.id
        };
        axios.post('api/GetCompanyAccounts/GetBundleProduct',params,{'Content-Type' : 'application/json'})
        .then((response) => {
            if(response.data != null || response.data != '' || response.data != undefined) {
                this.bundleProductsItemList = response.data.bundle_data;
            }
        })
        .catch(function (error){
            console.log(error);
        });
    },
    addBundleProduct() {
        const params = {
            session_id: this.$getUser().session_id,
            bundle_parent_id: this.form.id,
            bundle_product_id: this.bundleProductsForm.bundle_product_id !== '-1' ? this.bundleProductsForm.bundle_product_id : null,
            quantity: this.bundleProductsForm.quantity
        };
        axios.post('api/GetCompanyAccounts/CreateBundleProduct',params,{'Content-Type' : 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == '' || response.data.error == undefined) {
                this.bundleProductsForm.bundle_product_id = '-1';
                this.bundleProductsForm.quantity = 1.00;
                this.getBundleProducts();
            }
        })
        .catch(error => {
            console.log(error);
        });
    },
    onRowUpdating(e) {
        const parameters = {
            session_id: this.$getUser().session_id,
            bundle_product_id: e.oldData.id,
            quantity: e.newData.quantity ? parseFloat(e.newData.quantity.toString().split(',').join('')) : parseFloat(e.oldData.quantity.toString().split(',').join('')),
        };
        axios.post('/api/GetCompanyAccounts/UpdateBundleProduct', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.getBundleProducts();
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onSelectBundleItem(item) {
        this.bundleProductsItemList.forEach((value) => { 
            if (item.id !== value.id) {
                value.isSelected = false;
            }
        });
    },
    onRowDeleting(e) {
        const parameters = {
            session_id: this.$getUser().session_id,
            bundle_id: e.key,
            is_delete_all:  0,
            bundle_product_id : null,
        };
        axios.post('/api/GetCompanyAccounts/DeleteBundleProduct', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){                
                this.getData(true);
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    },
    onMasterBundleDelete(e){
        const params = {
            session_id: this.$getUser().session_id,
            id: e.data.transaction_type_id
        };
        axios.post('/api/Transaction/DeleteDocket',params,{'Content-type' : 'application/json'})
        .then((response) =>{
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.getData(true);
            }
        })
    },
    deleteBundleItem(isDeleteAll){
        let bundleId = null;
        this.bundleProductsItemList.forEach((item)=> {
            if(item.isSelected){
                bundleId = item.id;
            }
        });
        this.$swal.fire({
            title: "Are you sure you want to remove this item?",
            confirmButtonColor: "#f1416c",
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: "DELETE",
            icon: 'info'
        })
        .then((result) => {
            if (result.isConfirmed) {
                const parameters = {
                    session_id: this.$getUser().session_id,
                    bundle_id: bundleId,
                    is_delete_all: isDeleteAll ? 1 : 0,
                    bundle_product_id : isDeleteAll ? this.form.id : null
                }; 
                axios.post('/api/GetCompanyAccounts/DeleteBundleProduct', parameters, {'Content-Type': 'application/json'})
                .then((response) => {
                    if(response.data.error == null || response.data.error == '' || response.data.error == undefined){
                        this.bundleProductsItemList = response.data;
                        this.getBundleProducts();
                    } 
                }) 
                .catch(function (error) {
                    console.log(error);
                    this.Progress.finish();
                });
            }
        });
    },
    setCriteriasToDefault() {   
        this.bundleTransactionList = [];
        this.bundleFilterForm = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment()
        };
        this.getBundleTransactionData();
    },
    clearModalForm() {
        this.bundleForm = {
            record_date: moment(),
            quantity: 1.00
        };
        this.getCostOfSalesMethod();
    },
    createBundleTransaction() {
        const parameters = {
            session_id: this.$getUser().session_id,
            record_date: moment(this.bundleForm.record_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            quantity: this.bundleForm.quantity,
            stock_account_id: this.form.id
        };
        axios.post('/api/GetCompanyAccounts/CreateBundleTransaction', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
            if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
                this.$refs.onCloseBundleModal.click();
                this.getData(true);
                this.$swal("Success", "Product bundle succesfull..!", 'success');
            }
        })
        .catch(function (error) {
           console.log(error);
        });
    },
    getBundleTransactions() {
        this.bundleTransactionList = [];
        this.detailList = [];
        const params = {
            session_id: this.$getUser().session_id,
            account_id: this.form.id,
            currency: this.localCurrency,
            start_date: moment(this.bundleFilterForm.start_date).format('YYYY-MM-DD'),
            end_date: moment(this.bundleFilterForm.end_date).format('YYYY-MM-DD'),
        };
        axios.post('/api/GetCompanyAccounts/GetBundleTransactions', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.bundleTransactionList = response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
    },    
    onRowPrepared(e){
      if(e.rowType === 'data') {  
        if(e.data.row_type == 0) { 
          e.rowElement.classList.add('bg-material-gray-200');  
          e.rowElement.classList.add('fw-bolder');  
        }
      } 
    },
    getCostOfSalesMethod() {
        const parameters = {
          session_id: this.$getUser().session_id
        };  
        axios.post('/api/GetCompanyAccounts/CostOfSalesMethod', parameters, {'Content-Type': 'application/json'})
        .then((response) => {
           if(response.data.error == null || response.data.error == 'undefined' || response.data.error == ''){
              this.costOfSalesMethodName = response.data;
           }
        })
        .catch(function (error) {
           console.log(error);
        });
    }
  },
  created() {
    this.helpPageViewIcon();
    this.$removeSessionStorage('tmp_session_id');
    this.getData();
  },
};


</script>


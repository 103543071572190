<template> 
  <div> 
    <div class="ms-4">
        <div class="page-title flex-row me-3"> 
            <h1 class="d-flex fw-bolder my-1 fs-3 text-gray-800">{{ $t('General Stock Report') }}
            </h1> 
            <ul class="breadcrumb breadcrumb-dot fw-bold text-gray-600 fs-7 my-1"> 
                <li class="breadcrumb-item text-gray-600">
                    <a href="#" class="text-gray-600 text-hover-primary">
                        <router-link :to="{ name: 'Home' }">{{ $t('Home') }}</router-link>
                    </a>
                </li> 
                <li class="breadcrumb-item text-primary">{{ $t('Reports') }}</li> 
                <li class="breadcrumb-item text-primary">{{ $t('Stock Reports') }}</li>    
                <li class="breadcrumb-item text-gray-600">{{ $t('General Stock Report') }}
                    <HelpPageIcon v-if="this.iconView == true" :menuSelection="this.menuSelection"></HelpPageIcon> 
                </li> 
            </ul> 
        </div>  
    </div>
    <div class="content flex-row-fluid mt-4" id="kt_content">
        <div class="card">
            <div class="row fv-row border-0 pt-6 px-9">
                <div class="col-lg-9">
                    <div class="fv-row mb-4 row">  
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Stock Group') }}</span>
                            </label>
                            <Select2 v-model="this.form.stock_group" :options="this.stockGroupList" :settings="{ width: '100%' }"/>
                        </div>  
                         
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Product Group') }}</span>
                            </label>
                            <Select2 v-model="this.form.product_group" :options="this.productGroupList" :settings="{ width: '100%' }" @select="onChangeProductGroup()"/>
                        </div> 
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Product Type') }}</span>
                            </label>
                            <Select2 v-model="this.form.product_type" :options="this.productTypeList" :settings="{ width: '100%' }"/>
                        </div>   
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Report Type') }}</span>
                            </label>
                            <Select2 v-model="form.report_type" :options="reportTypes"  :settings="{ width: '100%' }"/>
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12" v-if="form.report_type == '2'">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('Start Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.start_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                        <div class="col-lg col-md-4 col-sm-6 col-xs-12" v-if="form.report_type == '2'">
                            <label class="fw-bold form-label mt-3">
                                <span>{{ $t('End Date') }}</span>
                            </label>
                            <Datepicker textInput :textInputOptions="{ format: 'dd.MM.yyyy' }" v-maska="'##.##.####'" v-model="form.end_date" inputClassName="form-control" format="dd.MM.yyyy" autoApply :clearable="false" weekNumbers :monthChangeOnScroll="'inverse'" :enableTimePicker="false"></Datepicker>  
                        </div>
                    </div>  
                </div>
                <div class="col-lg-3">
                    <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base" v-if="this.$isCredentialActive(72,'R')"> 
                        <Popper hover placement="top">
                            <button type="button" class="btn btn-light border border-gray-300 btn-icon me-3" @click="this.setCriteriasToDefault()">
                                <i class="bi bi-eraser-fill fs-4"> </i>
                            </button>
                            <template #content>
                                <span class="text-gray-800"> {{ $t('Reset All Criterias') }} </span>
                            </template>
                        </Popper> 
                        <button type="button" class="btn btn-light-primary border border-primary" @click="getListData()" data-kt-indicator="on">
                            <span class="svg-icon svg-icon-muted"><svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="6" width="16" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" y="12" width="8" height="3" rx="1.5" fill="currentColor"/>
                            <rect opacity="0.3" width="12" height="3" rx="1.5" fill="currentColor"/>
                            </svg>
                            </span>
                            <span v-if="!this.isListProgressing"> {{ $t('List Transactions') }} </span> 
                            <span class="indicator-progress" v-if="this.isListProgressing">
                                {{ $t('Getting Data...') }} <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span> 
                        </button> 
                    </div>
                    <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                        <div class="fw-bolder me-5">
                        <span class="me-2" data-kt-customer-table-select="selected_count"></span>{{ $t('Selected') }}</div>
                        <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">{{ $t('Delete Selected') }}</button>
                    </div>
                </div>
            </div>
            <div class="card-body pt-3">
                <div class="separator my-3 mt-0 border-gray-300" style="margin-top: -10px !important;"></div>
                <div class="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div class="table-responsive min-h-300px">
                        <ListOptions :name="this.$options.name" @click="SaveDXStateToStorage" ref="listOptionComponent"></ListOptions>
                         <DxDataGrid id="gridContainer"
                                :show-borders="true"
                                key-expr="id"
                                :data-source="lists"
                                :allow-column-reordering="true" 
                                :allow-column-resizing="true"
                                :column-auto-width="true"
                                :row-alternatin-enabled="true"
                                :hover-state-enabled="true" 
                                :show-row-lines="true" 
                                :ref="'dataGrid'"
                                @exporting="this.$onExporting($event , 'General-List')">   

                                <DxFilterRow :visible="true"/>
                                <DxHeaderFilter :visible="true"/>
                                <DxGroupPanel :visible="true"/>      
                                <DxExport :enabled="this.$getUser().is_allow_export" :formats="['xlsx', 'pdf']" :allow-export-selected-data="false"/>
                                <DxPaging :page-size="pageSize"/>
                                <DxPager :visible="false" :allowed-page-sizes="pageSizesLists" :show-page-size-selector="showPageSizeSelectorLists" :show-info="true" :show-navigation-buttons="true"/>    
                                <DxSearchPanel :visible="true"/>
                                <DxScrolling
                                    mode="virtual"
                                    row-rendering-mode="virtual"
                                />
                                <DxColumn data-field="stock_code" :caption="$t('Stock Code')" header-cell-template="stock-code-header" />
                                <DxColumn data-field="name" :caption="$t('Stock Name')" header-cell-template="stock-name-header" cell-template="stock-name-cell-template"/>
                                <DxColumn data-field="product_code" :caption="$t('Product Code')" header-cell-template="product-code-header"/>
                                <DxColumn data-field="turnover_quantity" :sorting-method="this.$numericSort" :caption="$t('Turnover Qty')" header-cell-template="turnover-qty-header" alignment="right"/>
                                <DxColumn data-field="turnover_amount" :sorting-method="this.$numericSort" :caption="$t('Turnover Amount')" header-cell-template="turnover-amount-header" alignment="right"/>
                                <DxColumn data-field="in_quantity" :sorting-method="this.$numericSort" :caption="$t('In Qty')" header-cell-template="in-qty-header" alignment="right"/>
                                <DxColumn data-field="in_amount" :sorting-method="this.$numericSort" :caption="$t('In Amount')" header-cell-template="in-amount-header" alignment="right"/>
                                <DxColumn data-field="out_quantity" :sorting-method="this.$numericSort" :caption="$t('Out Qty')" header-cell-template="out-qty-header" alignment="right"/>
                                <DxColumn data-field="out_amount" :sorting-method="this.$numericSort" :caption="$t('Out Amount')" header-cell-template="out-amount-header" alignment="right"/>
                                <DxColumn data-field="sale_quantity" :sorting-method="this.$numericSort" :caption="$t('Sale Cost Amount')" header-cell-template="sale-qty-header" alignment="right"/>
                                <DxColumn data-field="profit" :sorting-method="this.$numericSort" :caption="$t('Profit')" alignment="right"/>
                                <DxColumn data-field="current_quantity" :sorting-method="this.$numericSort" :caption="$t('Current Qty')" header-cell-template="current-qty-header" alignment="right"/>
                                <DxColumn data-field="current_amount" :sorting-method="this.$numericSort" :caption="$t('Current Amount')" header-cell-template="current-amount-header" alignment="right"/>
                                <DxColumn data-field="current_quantity2" :sorting-method="this.$numericSort" :caption="$t('Quantity 2')"  alignment="right"/>
                                <DxColumn data-field="stock_group_name" :caption="$t('Stock Group')" header-cell-template="stock-group-header"/> 
                                <DxColumn data-field="product_group_name" :caption="$t('Product Group')" header-cell-template="product-group-header"/> 
                                <DxColumn data-field="product_type_name" :caption="$t('Product Type')" header-cell-template="product-type-header"/> 
                                <DxColumn data-field="product_detail_name" :caption="$t('Product Detail')" header-cell-template="product-detail-header"/> 

                                <template #stock-code-header>
                                    <div>{{ $t('Stock') }}</div><div>{{ $t('Code') }}</div>
                                </template>
                                <template #stock-name-header>
                                    <div>{{ $t('Stock') }}</div><div>{{ $t('Name') }}</div>
                                </template>
                                <template #product-code-header>
                                    <div>{{ $t('Product') }}</div><div>{{ $t('Code') }}</div>
                                </template>
                                <template #turnover-qty-header>
                                    <div>{{ $t('Turnover') }}</div><div>{{ $t('Quantity') }}</div>
                                </template>
                                <template #turnover-amount-header>
                                    <div>{{ $t('Turnover') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #in-qty-header>
                                    <div>{{ $t('In') }}</div><div>{{ $t('Quantity') }}</div>
                                </template>
                                <template #in-amount-header>
                                    <div>{{ $t('In') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #out-qty-header>
                                    <div>{{ $t('Out') }}</div><div>{{ $t('Quantity') }}</div>
                                </template>
                                <template #out-amount-header>
                                    <div>{{ $t('Out') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #sale-qty-header>
                                    <div>{{ $t('Sale') }}</div><div>{{ $t('Quantity') }}</div>
                                </template>
                                <template #current-qty-header>
                                    <div>{{ $t('Current') }}</div><div>{{ $t('Quantity') }}</div>
                                </template>
                                <template #current-amount-header>
                                    <div>{{ $t('Current') }}</div><div>{{ $t('Amount') }}</div>
                                </template>
                                <template #stock-group-header>
                                    <div>{{ $t('Stock') }}</div><div>{{ $t('Group') }}</div>
                                </template>
                                <template #product-group-header>
                                    <div>{{ $t('Product') }}</div><div>{{ $t('Group') }}</div>
                                </template>
                                <template #product-type-header>
                                    <div>{{ $t('Product') }}</div><div>{{ $t('Type') }}</div>
                                </template>
                                <template #product-detail-header>
                                    <div>{{ $t('Product') }}</div><div>{{ $t('Detail') }}</div>
                                </template>

                                
                                <template #stock-name-cell-template=" {data}">
                                    <div> {{data.data.name}} <small v-if="data.data.is_bundle_product == 1" class="ms-2 badge badge-sm badge-success">{{ $t('Bundle') }}</small></div>
                                </template>

                                
                                <DxSummary :calculate-custom-summary="setDetailSummaries">
                                    <DxTotalItem column="id" summary-type="count" display-format="Record: {0}" show-in-column="stock_code"/>

                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="in_quantity"
                                        summary-type="custom"
                                        show-in-column="in_quantity"
                                        display-format="{0}"
                                    />  
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="in_amount"
                                        summary-type="custom"
                                        show-in-column="in_amount"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="out_quantity"
                                        summary-type="custom"
                                        show-in-column="out_quantity"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="out_amount"
                                        summary-type="custom"
                                        show-in-column="out_amount"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="sale_quantity"
                                        summary-type="custom"
                                        show-in-column="sale_quantity"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="profit"
                                        summary-type="custom"
                                        show-in-column="profit"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="current_amount"
                                        summary-type="custom"
                                        show-in-column="current_amount"
                                        display-format="{0}"
                                    /> 
                                    <DxGroupItem
                                        :show-in-group-footer="true"
                                        name="current_quantity2"
                                        summary-type="custom"
                                        show-in-column="current_quantity2"
                                        display-format="{0}"
                                    /> 
                                    <DxTotalItem
                                        name="in_quantity"
                                        summary-type="custom"
                                        show-in-column="in_quantity"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="in_amount"
                                        summary-type="custom"
                                        show-in-column="in_amount"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="out_quantity"
                                        summary-type="custom"
                                        show-in-column="out_quantity"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="out_amount"
                                        summary-type="custom"
                                        show-in-column="out_amount"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="sale_quantity"
                                        summary-type="custom"
                                        show-in-column="sale_quantity"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="profit"
                                        summary-type="custom"
                                        show-in-column="profit"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="current_quantity"
                                        summary-type="custom"
                                        show-in-column="current_quantity"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="current_amount"
                                        summary-type="custom"
                                        show-in-column="current_amount"
                                        display-format="{0}"
                                    />
                                    <DxTotalItem
                                        name="current_quantity2"
                                        summary-type="custom"
                                        show-in-column="current_quantity2"
                                        display-format="{0}"
                                    />
                                </DxSummary>

                                <DxStateStoring
                                    :enabled="true"
                                    type="custom"
                                    :storage-key="this.storageKey"
                                    :ref="dataGrid" 
                                    :saving-timeout="1000"
                                    :custom-load="LoadDXState"
                                />
                        </DxDataGrid>     
                    </div>  
                </div> 
                </div>
        </div> 
    </div>
  </div>  
</template>
<script>
import axios from 'axios' 
// import Swal from 'sweetalert2';
import moment from 'moment'; 
import { DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn,DxGroupItem, DxSearchPanel, DxStateStoring, DxTotalItem, DxSummary, DxScrolling } from 'devextreme-vue/data-grid';
 
export default {
  name: "GeneralStockTransactionList",
  components:{
    DxDataGrid, DxFilterRow, DxHeaderFilter, DxGroupPanel, DxExport, DxPaging, DxPager, DxColumn,DxGroupItem, DxSearchPanel, DxStateStoring, DxTotalItem, DxSummary, DxScrolling
  }, 
  data() {
    return {
        storageKey: 'dx_' + this.$options.name,
        pageSize: 100,
        pageSizeDetailList: 10,
        page_size: 20,
        lists: [],
        form:  {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            stock_group: '-1',
            product_group: '-1',
            product_type: '-1',
            report_type: '1'
        },
        isListProgressing: false,
        isFilterOpen: false,
        stockGroupList: [],
        productGroupList: [],
        productTypeList: [],
        reportTypes: [{ id: '1', text: 'General'}, { id: '2', text: 'Date Range'}],
        menuSelection: '/GeneralStockReportHelppage',
        iconView : false
    };
  },
  computed: {
   previewDate(){
      return (value)=>{
       if (value) {
          return moment(new Date(value)).format('DD.MM.YYYY')
        }
      }
    },
    showPageSizeSelectorLists() {
        return this.lists.length > this.pageSize;
    },
    pageSizesLists() {
        let arr = [];
        let x = 1;
        let loop = (this.lists.length / this.pageSize);
        for (let i = 1; i <= loop && i <= 5; i++) {
            arr.push(this.pageSize * x);
            x++;
        }
        if(loop > 5) arr.push(1000);
        if(loop > 1) arr.push('all');
        return arr;
    },
  },
  methods: {
    getData(){ 
            this.$Progress.start();
            const parameters = {
                session_id: this.$getUser().session_id,
                list_types: [{ list_type: 6, list_name: "stock_group"}],
                list_name: ["currency", "account_setting", "product_group"],
                account_type: 7 // yalnızca account settings listesi çekilecek ise gerekli
            };  
            
            const requestOne =  axios.post('/api/Helper/GetMultiLists', parameters, {'Content-Type': 'application/json'});
            const requestTwo =  axios.post('/api/Helper/GeneralLists', parameters, {'Content-Type': 'application/json'}); 
            
            axios.all([requestOne, requestTwo]).then(axios.spread((...responses) => {
                    this.stockGroupList = responses[0].data.stock_group; 
                    this.productGroupList = responses[1].data.product_group;
                    this.stockGroupList.unshift({ id: '-1', text: 'ALL'}); 
                    this.productGroupList.unshift({ id: '-1', text: 'ALL'});
                    this.productTypeList.unshift({ id: '-1', text: 'ALL'}); 
                    this.productGroupList.forEach((value) => {
                        if(value.is_default) {
                            this.form.product_group_id = value.id;
                        }
                    });
                    this.$Progress.finish();
                })
              )
              .catch(errors => { 
                console.error(errors); 
                this.$Progress.finish();
            });  
    },
    onChangeProductGroup() {
        this.productTypeList = [];
        const params = {
            session_id: this.$getUser().session_id,
            parent_id: this.form.product_group
        };
        axios.post("/api/Helper/GetProductTypeParentList", params, {'Content-Type': 'application/json'}).then((response) => { 
            this.productTypeList = response.data;
            this.productTypeList.unshift({ id: '-1', text: 'ALL'}); 
            this.form.product_type = this.productTypeList[0].id;
            this.productTypeList.forEach((value) => {
                if(value.is_default) {
                    this.form.product_type = value.id;
                }
            });
            this.$Progress.finish();
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
        });
    },
    setCriteriasToDefault() {   
        this.form = {
            start_date: moment().subtract((parseInt(this.$getUser().list_date_range)), 'days'),
            end_date: moment(),
            stock_group: '-1',
            product_group: '-1',
            product_type: '-1',
            report_type: '1'
        };
        this.lists = [];
    },
    getListData() {
        this.$Progress.start();
        this.isListProgressing = true;
        this.form.start_date = (this.form.start_date == null || this.form.start_date == '') ? moment() : this.form.start_date;
        this.form.end_date = ( this.form.end_date == null || this.form.end_date == '') ? moment () : this.form.end_date; 
        const params = {
            session_id: this.$getUser().session_id,
            start_date: moment(this.form.start_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            end_date: moment(this.form.end_date).format('YYYY-MM-DD[T]HH:mm:ss'),
            report_type: this.form.report_type,
            stock_group: null,
            product_group: null,
            product_type: null
        };
        params.stock_group = this.form.stock_group == '-1'? null : this.form.stock_group;
        params.product_group = this.form.product_group == '-1'? null : this.form.product_group;
        params.product_type = this.form.product_type == '-1'? null : this.form.product_type;
        axios.post('/api/StockReports/GetGeneralStockList', params, {'Content-Type': 'application/json'})
        .then((response) => {
            this.lists = !response.data.error ? response.data.report_data : [];
            this.page = 1;
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        })
        .catch(function (error) {
            console.log(error);
            this.$Progress.finish();
            setTimeout(() => {
                this.isListProgressing = false;
            }, 500);
        });  
    },
    /** DX STATE OPERATIONS **/  
    LoadDXState() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }, 
    SaveDXStateToStorage() { 
        const state = this.$refs.dataGrid.instance.state();
        localStorage.setItem(this.storageKey, JSON.stringify(state));
        this.$refs.listOptionComponent.getState();        
    },
    async helpPageViewIcon()
    {
        const queryparameter = {
            session_id : this.$getUser().session_id,
            menu_selection : '/GeneralStockReportHelppage'
        };
        axios.post('api/HelpPage/HelpPagesContent', queryparameter, {'Content-Type': 'application/json'})
        .then((response) => { 
            if(!response.data.error){ 
                this.iconView = response.data;
            }
        })
    }, 
    setDetailSummaries(options){  
        const optionNames = ["in_quantity", "in_amount", "out_quantity", "out_amount", "sale_quantity", "profit", "current_quantity", "current_amount", "current_quantity2"]; 
        optionNames.forEach(item => {
            if(options.name == item){
                if(options.summaryProcess === 'start'){ options.totalValue = 0; }
                else if(options.summaryProcess === 'calculate'){
                    var itemValue = parseFloat(options.value[item].replaceAll(',', ''));
                    (options.totalValue += itemValue);
                }
                else if(options.summaryProcess === 'finalize'){ 
                    options.totalValue = Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(options.totalValue);
                }
            }
        });
    },
  },
  created() {
    this.helpPageViewIcon();
    this.$removeSessionStorage('record_id');
    this.lists = [];
    this.getData();
  }
}; 
</script>